import axiosInstance from "./htttp.service";

class StudentApiService {
  axiosInstances = axiosInstance;

  // A list of All Student List
  getStudentsList = async (pageSize, pageIndex, status, name, orderBy) => {
    return await this.axiosInstances.get(
      `/attendance/students/?page_size=${pageSize}&page=${pageIndex}&status=${status}&search=${name}&ordering=${orderBy}`
    );
  };

  // Individual Student Information
  getStudentInformation = async (id) => {
    return await this.axiosInstances.get(`/attendance/students/${id}`);
  };

  // Individual Student Details
  getStudentConfigDetails = async (id) => {
    return await this.axiosInstances.get(
      `/attendance/student-details/?student=${id}`
    );
  };

  // Get additional students that are not in the list
  getAdditionalStudentsForSchedule = async (id) => {
    return await this.axiosInstances.get(
      `/attendance/student-schedule/?search=${id}&student__status=Active`
    );
  };

  // Individual Student Attendance Record
  getStudentAttendance = async (
    id,
    startDate,
    endDate,
    pageSize,
    pageIndex
  ) => {
    return await this.axiosInstances.get(
      `/attendance/students-attendace/?student=${id}&page_size=${pageSize}&page=${pageIndex}&date_after=${startDate}&date_before=${endDate}`
    );
  };

  // Get All Students Attendance Record
  getAllStudentsAttendance = async (
    startDate,
    endDate,
    pageSize,
    pageIndex
  ) => {
    return await this.axiosInstances.get(
      `attendance/students-attendace/?page_size=${pageSize}&page=${pageIndex}&date_after=${startDate}&date_before=${endDate}`
    );
  };

  // Individual Student Payment Record
  getStudentPayment = async (id, startDate, endDate, pageSize, pageIndex) => {
    return await this.axiosInstances.get(
      `/attendance/paid-amount/?student=${id}&page_size=${pageSize}&page=${pageIndex}&payment_date_after=${startDate}&payment_date_before=${endDate}`
    );
  };

  // Get All Students Attendance Record
  getAllStudentsPayments = async (startDate, endDate, pageSize, pageIndex) => {
    return await this.axiosInstances.get(
      `attendance/paid-amount/?page_size=${pageSize}&page=${pageIndex}&payment_date_after=${startDate}&payment_date_before=${endDate}`
    );
  };

  // Get All Students Schedule List
  getIndividualStudentSchedules = async (studentId) => {
    return await this.axiosInstances.get(
      `/attendance/student-schedule/?student=${studentId}`
    );
  };

  // Get All Students Schedule List
  getStudentsScheduleList = async (status) => {
    return await this.axiosInstances.get(
      `/attendance/student-schedule/?student__status=${status}`
    );
  };

  // Get All Students Schedule List by Day
  getStudentsScheduleListByDayAndName = async (day, name) => {
    return await this.axiosInstances.get(
      `/attendance/student-schedule/?day=${day}&search=${name}`
    );
  };

  // Get All Students Schedule List by Day
  getStudentsScheduleListByDay = async (day, status) => {
    return await this.axiosInstances.get(
      `/attendance/student-schedule/?day=${day}&student__status=${status}`
    );
  };

  // Get All Students Attendance on Hold List
  getStudentAttendanceOnHold = async () => {
    return await this.axiosInstances.get(`/attendance/attendance-on-hold/`);
  };

  // Individual Unpaid information
  getStudentUnpaidInformation = async (id) => {
    return await this.axiosInstances.get(
      `/attendance/student-unpaid-classes/${id}`
    );
  };

  downloadStudentsListCSV = async () => {
    return await this.axiosInstances.get(`/attendance/students/download_csv`);
  };

  // Add new student
  addStudent = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(`attendance/students/`, payload, {
      headers,
    });
  };

  // Update Student Details
  updateStudent = async (payload, id) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.put(`attendance/students/${id}`, payload, {
      headers,
    });
  };

  // Add Student Schedule
  addStudentSchedule = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `attendance/student-schedule/`,
      payload,
      {
        headers,
      }
    );
  };

  // Upsert Student Details COnfig
  updateConfigDetails = async (id, payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.put(
      `attendance/student-details/${id}`,
      payload,
      {
        headers,
      }
    );
  };

  // Upsert Student Details COnfig
  addNewStudentConfigDetails = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `attendance/student-details/`,
      payload,
      {
        headers,
      }
    );
  };

  // Delete Student Schedule
  deleteStudentSchedule = async (id) => {
    return await this.axiosInstances.delete(
      `/attendance/student-schedule/${id}/`
    );
  };

  // Get student cost per class
  getStudentsCostPerClass = async (id) => {
    return await this.axiosInstances.get(
      `/attendance/cost-for-student/?student=${id}`
    );
  };

  // Download DB Backup
  downloadDBBackUp = async () => {
    return await this.axiosInstances.get(
      `/attendance/database_backup/download_backup`
    );
  };
}

export default new StudentApiService();
