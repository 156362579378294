import axiosInstance from "./htttp.service";

class PayerApiService {
  axiosInstances = axiosInstance;

  // Individual Payer Information
  getPayerInformation = async (id) => {
    return await this.axiosInstances.get(`/payment/payers/${id}`);
  };

  // A list of All Payers List
  getPayersList = async (pageSize, pageIndex, status, name, orderBy) => {
    return await this.axiosInstances.get(
      `/payment/payers/?page_size=${pageSize}&page=${pageIndex}&status=${status}&search=${name}&ordering=${orderBy}`
    );
  };

  // A list of All Payers List
  getAssociatedPayersForStudent = async (studentUUID) => {
    return await this.axiosInstances.get(
      `/payment/payers/?search=${studentUUID}`
    );
  };

  // Add new Payer
  addPayer = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(`payment/payers/`, payload, {
      headers,
    });
  };

  // Update Student Details
  updatePayer = async (payload, id) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.put(`payment/payers/${id}`, payload, {
      headers,
    });
  };

  // Associate students
  associateStudent = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `payment/associate-payer-student/`,
      payload,
      {
        headers,
      }
    );
  };

  // Associate students
  sendCustomMessage = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `payment/send-custom-message/`,
      payload,
      {
        headers,
      }
    );
  };

  // send unpaid message
  sendUnpaidAmountMessage = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `payment/send-unpaid-amounts/`,
      payload,
      {
        headers,
      }
    );
  };

  detachStudent = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.delete(
      `payment/associate-payer-student/`,
      {
        headers,
        data: payload,
      }
    );
  };
}

export default new PayerApiService();
