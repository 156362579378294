/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts

// @mui icons
import Icon from "@mui/material/Icon";
import AdminSettings from "appStack/adminSettings";
import Attendance from "appStack/attendance";
import CorporateCustomers from "appStack/corporateCustomers";
import Dashboard from "appStack/dashboard";
import ParentStudentDetails from "appStack/parents/parentStudentDetails";
import PayerAssociatedStudents from "appStack/parents/parentStudents";
import Payers from "appStack/payers";
import PayerStudents from "appStack/payers/payerStudents";
import Payments from "appStack/payments";
import ProspectStudentSignUp from "appStack/prospectStudentSignUp";
import Schedule from "appStack/schedule";
import SMS from "appStack/sms";
import Students from "appStack/students";
import TakeAttendance from "appStack/students-take-attendance";
import StudentDetails from "appStack/students/studentDetails";
import VerifyAttendace from "appStack/verifyAttendance";

const routes = [
  // {
  //   type: "innerCollapse",
  //   name: "Home_Path",
  //   key: "dashboard_initial",
  //   route: "/",
  //   role: ["staff", "admin", "superadmin"],
  //   component: <Dashboard />,
  // },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    role: ["superadmin", "admin"],
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/students",
    role: ["staff", "admin", "superadmin"],
    component: <Students />,
  },
  {
    type: "collapse",
    name: "SMS",
    key: "sms",
    icon: <Icon fontSize="small">sms</Icon>,
    route: "/sms",
    role: ["staff", "admin", "superadmin"],
    component: <SMS />,
  },
  {
    type: "collapse",
    name: "Payers",
    key: "payers",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/payers",
    role: ["admin", "superadmin"],
    component: <Payers />,
  },
  {
    type: "innerCollapse",
    name: "Payers Students",
    key: "payer_students",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/payers/:id",
    role: ["admin", "superadmin"],
    component: <PayerStudents />,
  },
  {
    type: "innerCollapse",
    name: "Student Details",
    key: "student_details",
    route: "/students/:id",
    role: ["staff", "admin", "superadmin"],
    component: <StudentDetails />,
  },
  {
    type: "collapse",
    name: "Schedule",
    key: "schedule",
    icon: <Icon fontSize="small">calendar_today</Icon>,
    route: "/schedule",
    role: ["staff", "admin", "superadmin"],
    component: <Schedule />,
  },
  {
    type: "innerCollapse",
    name: "Schedule",
    key: "schedule",
    icon: <Icon fontSize="small">calendar_today</Icon>,
    route: "/",
    role: ["staff", "admin", "superadmin"],
    component: <Schedule />,
  },
  {
    type: "collapse",
    name: "Attendance",
    key: "attendance",
    icon: <Icon fontSize="small">event_available</Icon>,
    route: "/attendance",
    role: ["staff", "admin", "superadmin"],
    component: <Attendance />,
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/payments",
    role: ["staff", "admin", "superadmin"],
    component: <Payments />,
  },
  {
    type: "collapse",
    name: "Verify Attendance",
    key: "verify-attendance",
    icon: <Icon fontSize="small">checklist</Icon>,
    route: "/verify-attendance",
    role: ["staff", "admin", "superadmin"],
    component: <VerifyAttendace />,
  },
  {
    type: "collapse",
    name: "Admin Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    role: ["superadmin", "admin"],
    component: <AdminSettings />,
  },
  {
    type: "collapse",
    name: "Corporate Customers",
    key: "corporate-customers",
    icon: <Icon fontSize="small">business</Icon>,
    route: "/corporate-customers",
    role: ["staff", "admin", "superadmin"],
    component: <CorporateCustomers />,
  },

  // Student Routes
  {
    type: "innerCollapse",
    name: "Today's Attendance Route",
    key: "Todays_attendance_route",
    route: "/",
    role: ["student"],
    component: <TakeAttendance />,
  },
  {
    type: "collapse",
    name: "Today's Attendance",
    key: "Todays_attendance",
    icon: <Icon fontSize="small">event_available</Icon>,
    route: "/todays-attendance",
    role: ["student"],
    component: <TakeAttendance />,
  },
  // Prospect Student Routes
  {
    type: "innerCollapse",
    name: "Prospect Student",
    key: "prospect-student",
    route: "/registerprospectstudent",
    role: ["staff", "admin", "superadmin", "student"],
    component: <ProspectStudentSignUp />,
  },

  // Parent Routes
  {
    type: "innerCollapse",
    name: "Today's Attendance Route",
    key: "Todays_attendance_route",
    route: "/",
    role: ["parent"],
    component: <PayerAssociatedStudents />,
  },
  {
    type: "collapse",
    name: "Associated Students",
    key: "associated-students",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/associated-students",
    role: ["parent"],
    component: <PayerAssociatedStudents />,
  },
  {
    type: "innerCollapse",
    name: "Associated Student Details",
    key: "associated-student-details",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/associated-students/:id",
    role: ["parent"],
    component: <ParentStudentDetails />,
  },
];

export default routes;
