// @mui material components

// Material Dashboard 2 React components
// Material Dashboard 2 React example components
import APIError from "components/ApiError";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../../layouts/LayoutContainers/DashboardLayout";
// @mui material components
import { Grid } from "@mui/material";
import DisplayBox from "appStack/students/studentReports/ReportDisplayBox";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import parentAPIService from "services/parentAPI-service";
// Material Dashboard 2 React components
import studentAvatar from "assets/images/person.png";
import { Link } from "react-router-dom";

function PayerAssociatedStudents() {
  const [payerName, setPayerName] = useState("");
  const [payerDetails, setPayerDetails] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    fetchPayerInformation();
  }, []);

  const fetchPayerInformation = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const response = await parentAPIService.getMyAssociatedStudents();
      setPayerName(
        response.data.results[0].first_name +
          " " +
          response.data.results[0].last_name
      );
      setPayerDetails(response.data.results[0]);
      setProcessing(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Displaying Student Information */}
      <MDBox pt={1} pb={3}>
        {/* Welcome Message */}
        <MDTypography variant="h7" fontWeight="medium">
          Welcome {payerName},
        </MDTypography>
        <br />
        <MDTypography variant="h8" fontWeight="regular">
          We're thrilled to have your child exploring the world of art with us.
          Please click on the student name below to view the students details.
        </MDTypography>

        {/* Associated Students */}
        {payerDetails?.associated_students?.length > 0 && (
          <MDBox pt={3} px={2} pb={5}>
            {/* Grid Display */}
            <Grid container spacing={2}>
              {payerDetails?.associated_students.map((payer, index) => (
                <Grid item xs={12} lg={6} key={"Payer" + index}>
                  {/* Outer Box */}
                  <MDBox
                    position="relative"
                    variant="contained"
                    sx={{ height: "100%" }}
                    shadow="xl"
                    coloredShadow="dark"
                    borderRadius="xl"
                    bgColor="white"
                    px={2}
                    py={2}
                  >
                    {/* Display Student Information here */}
                    {/* Student Avatar + Name */}
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <MDAvatar
                        src={studentAvatar}
                        alt="profile-image"
                        size="xl"
                        shadow="sm"
                      />
                      <Link
                        to={`/associated-students/${payer.student.student_UUID}?studentName=${payer.student.first_name} ${payer.student.last_name}&unpaidAmount=${payer.student.unpaid_amount}`}
                      >
                        <MDTypography
                          variant="h6"
                          color="text"
                          sx={{ cursor: "pointer" }}
                        >
                          {payer.student.first_name} {payer.student.last_name}
                        </MDTypography>
                      </Link>
                    </MDBox>

                    {/* Student Unpaid Amount */}
                    <MDBox p={0} m={0}>
                      <DisplayBox
                        color={
                          payer.student.unpaid_amount <= 0 ? "success" : "error"
                        }
                        icon="price_change"
                        name={`Current Balance`}
                        description=""
                        value={`$ ${payer.student.unpaid_amount}`}
                      />
                      {/* Negative Indicator */}
                      {payer.student.unpaid_amount < 0 && (
                        <MDTypography
                          variant="h7"
                          fontWeight="regular"
                          color="secondary"
                        >
                          -ve indicates a credit amount
                        </MDTypography>
                      )}

                      {payer.student.unpaid_amount === 0 && (
                        <MDTypography variant="h7" color="success">
                          No Payment Due at this time
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        )}
      </MDBox>
      {renderSnackBar}
      {/* API Error */}
      {!processing && apiError && <APIError />}
      <Footer />
    </DashboardLayout>
  );
}

export default PayerAssociatedStudents;
