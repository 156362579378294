// @mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import Footer from "../../components/Footer";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";

import { Card, Icon, IconButton, LinearProgress, Menu } from "@mui/material";
import { HintTexts } from "assets/constants/HintText";
import APIError from "components/ApiError";
import TwoGridCard from "components/Cards/TwoGridCard";
import ReportsBarChart from "components/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "components/Charts/LineCharts/ReportsLineChart";
import dateFormatter from "components/Formatter/DateFormatter";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import NotificationItem from "components/NotificationItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import analyticAPIService from "services/analyticAPI-service";
import ComplexStatisticsCard from "../../components/Cards/StatisticsCards/ComplexStatisticsCard";

function Dashboard() {
  const userSelector = useSelector((state) => state.auth);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  const [activeUnpaidAmount, setActiveUnpaidAmount] = useState("");
  const [inactiveUnpaidAmount, setInActiveUnpaidAmount] = useState("");
  const [activeStudentCount, setActiveStudentCount] = useState("");
  const [inactiveStudentCount, setInactiveStudentCount] = useState("");
  const [prospectiveStudentCount, setProspectiveStudentCount] = useState("");
  const [totalPrepaidAmount, setTotalPrepaidAmount] = useState("");
  const [totalUnpaidAmount, setTotalUnpaidAmount] = useState("");

  const [paidAmountStats, setPaidAmountStats] = useState([]);
  const [attendanceStats, setAttendanceStats] = useState([]);

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  // Dashboard Graph
  const fitlerAttendanceDays = ["1W", "2W", "1M", "2M"];
  const fitlerAmountDays = ["1W", "2W", "3W", "1M"];
  const [openAttendanceMenu, setOpenAttendanceMenu] = useState(false);
  const [openAmountMenu, setOpenAmountMenu] = useState(false);

  const [attendanceProcessing, setAttendanceProcessing] = useState(true);
  const [attendanceDashBoardRange, setAttendanceDashBoardRange] = useState(
    fitlerAttendanceDays.at(0)
  );
  const [attendanceStatsData, setAttendanceStatsData] = useState({});

  const [paidProcessing, setPaidProcessing] = useState(true);
  const [paidAmountDashBoardRange, setPaidAmountDashBoardRange] = useState(
    fitlerAmountDays.at(0)
  );
  const [paidAmoutStatsData, setPaidAmountStatsData] = useState({});

  useEffect(() => {
    fetchDashboardStudentsData();
  }, []);

  useEffect(() => {
    fetchAttendanceData();
  }, [attendanceDashBoardRange]);

  useEffect(() => {
    fetchPaidAmountData();
  }, [paidAmountDashBoardRange]);

  const fetchAttendanceData = async () => {
    setAttendanceProcessing(true);

    try {
      let todaysDate = new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
      let startDate = getStartDate(attendanceDashBoardRange);

      const dashboardStudentData =
        await analyticAPIService.getAttendanceStatistics(startDate, todaysDate);

      // Populate the attendace stats
      if (dashboardStudentData?.data) {
        setAttendanceStats({
          max_count: dashboardStudentData.data?.max_count,
          mean_count: dashboardStudentData.data?.mean_count,
          median_count: dashboardStudentData.data?.median_count,
          min_count: dashboardStudentData.data?.min_count,
          mode_count: dashboardStudentData.data?.mode_count,
          total_count_of_attendance:
            dashboardStudentData.data?.total_count_of_attendance,
        });
      }

      // Now process data
      if (dashboardStudentData?.data?.daily_stats.length > 0) {
        let labels = [];
        let attendaceCount = [];
        dashboardStudentData?.data?.daily_stats.forEach((data) => {
          labels.push(dateFormatter(data.attendance_date));
          attendaceCount.push(data.count);
        });

        setAttendanceStatsData({
          labels: labels,
          datasets: { label: "Count", data: attendaceCount },
        });
        setAttendanceProcessing(false);
      } else {
        setAttendanceStatsData({});
        setAttendanceProcessing(false);
      }
    } catch (error) {
      setAttendanceProcessing(false);
    }
  };

  const fetchPaidAmountData = async () => {
    setPaidProcessing(true);

    try {
      let todaysDate = new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
      let startDate = getStartDate(paidAmountDashBoardRange);

      const paidAmountResponse =
        await analyticAPIService.getPaidAmountStatistics(startDate, todaysDate);

      // Populate the attendace stats
      if (paidAmountResponse?.data) {
        setPaidAmountStats({
          max_paid_amount: paidAmountResponse.data?.max_paid_amount,
          mean_paid_amount: paidAmountResponse.data?.mean_paid_amount,
          median_paid_amount: paidAmountResponse.data?.median_paid_amount,
          min_paid_amount: paidAmountResponse.data?.min_paid_amount,
          mode_paid_amount: paidAmountResponse.data?.mode_paid_amount,
          std_dev_paid_amount: paidAmountResponse.data?.std_dev_paid_amount,
          total_paid_amount: paidAmountResponse.data?.total_paid_amount,
        });
      }

      // Now process data
      if (paidAmountResponse?.data?.daily_stats.length > 0) {
        let labels = [];
        let paidAmountSum = [];

        paidAmountResponse?.data?.daily_stats.forEach((data) => {
          labels.push(dateFormatter(data.date));
          paidAmountSum.push(data.sum);
        });

        setPaidAmountStatsData({
          labels: labels,
          datasets: { label: "Total Revenue", data: paidAmountSum },
        });
        setPaidProcessing(false);
      } else {
        setPaidAmountStatsData({});
        setPaidProcessing(false);
      }
    } catch (error) {
      setPaidProcessing(false);
    }
  };

  const getStartDate = (filter) => {
    const currentDate = new Date();

    if (filter.endsWith("W")) {
      const weeks = parseInt(filter.slice(0, -1), 10);
      currentDate.setDate(currentDate.getDate() - weeks * 7);
    } else if (filter.endsWith("M")) {
      const months = parseInt(filter.slice(0, -1), 10);
      currentDate.setMonth(currentDate.getMonth() - months);
    } else {
      throw new Error("Invalid filter format");
    }

    return currentDate
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-"); // Get YYYY-MM-DD format
  };

  const fetchDashboardStudentsData = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const dashboardStudentData =
        await analyticAPIService.getStudentStatistics();

      setActiveUnpaidAmount(
        dashboardStudentData.data?.active_students_unpaid_amount
      );
      setInActiveUnpaidAmount(
        dashboardStudentData.data?.inactive_students_unpaid_amount
      );

      dashboardStudentData.data?.status_count.forEach((count) => {
        switch (count.status) {
          case "Active":
            setActiveStudentCount(count.count);
            break;
          case "Inactive":
            setInactiveStudentCount(count.count);
            break;
          case "Prospective":
            setProspectiveStudentCount(count.count);
            break;
        }
      });

      setTotalPrepaidAmount(dashboardStudentData.data?.total_prepaid_amount);
      setTotalUnpaidAmount(dashboardStudentData.data?.total_unpaid_amount);

      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const getRangeDescription = (range) => {
    switch (range) {
      case "1W":
        return "Last 1 week";
      case "2W":
        return "Last 2 weeks";
      case "3W":
        return "Last 3 weeks";
      case "1M":
        return "Last 1 month";
      case "2M":
        return "Last 2 months";
      default:
        return "Last 1 week";
    }
  };

  const handleOpenAttendanceMenu = (event) => {
    setOpenAttendanceMenu(event.currentTarget);
  };

  const handleOpenAmountMenu = (event) => {
    setOpenAmountMenu(event.currentTarget);
  };

  const handleCloseAttendanceMenu = () => setOpenAttendanceMenu(false);
  const handleCloseAmountMenu = () => setOpenAmountMenu(false);

  const handleAttendanceFilterChange = (event) => {
    setAttendanceDashBoardRange(event);
    setOpenAttendanceMenu(false);
  };

  const handleAmountFilterChange = (event) => {
    setPaidAmountDashBoardRange(event);
    setOpenAmountMenu(false);
  };

  const renderAttendanceMenu = (
    <Menu
      anchorEl={openAttendanceMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openAttendanceMenu)}
      onClose={handleCloseAttendanceMenu}
      sx={{ mt: 2 }}
    >
      {fitlerAttendanceDays.map((day) => (
        <NotificationItem
          key={day}
          icon={<Icon>schedule</Icon>}
          title={getRangeDescription(day)}
          onClick={() => handleAttendanceFilterChange(day)}
        />
      ))}
    </Menu>
  );

  const renderAmountMenu = (
    <Menu
      anchorEl={openAmountMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openAmountMenu)}
      onClose={handleCloseAmountMenu}
      sx={{ mt: 2 }}
    >
      {fitlerAmountDays.map((day) => (
        <NotificationItem
          key={day}
          icon={<Icon>schedule</Icon>}
          title={getRangeDescription(day)}
          onClick={() => handleAmountFilterChange(day)}
        />
      ))}
    </Menu>
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {processing && (
        <MDBox pt={2} px={2}>
          <LinearProgress
            color="info"
            variant="indeterminate"
            sx={{ overflow: "hidden" }}
          />
        </MDBox>
      )}

      {!processing && (
        <MDBox py={3}>
          {/* Cards Data */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="person_off"
                  title="Inactive Students"
                  count={inactiveStudentCount}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="school"
                  title="Active Students"
                  count={activeStudentCount}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Prospective Students"
                  count={prospectiveStudentCount}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="paid"
                  title="Total Unpaid Amount"
                  count={`$ ${totalUnpaidAmount}`}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="paid"
                  title="Active Students Unpaid Amount"
                  count={`$ ${activeUnpaidAmount}`}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="paid"
                  title="Inactive Students Unpaid Amount"
                  count={`$ ${inactiveUnpaidAmount}`}
                />
              </MDBox>
            </Grid>
          </Grid>
          {/* Graph Data */}
          <Grid container spacing={3} mt={5} rowGap={8} mb={8}>
            <Grid item xs={12} md={6}>
              <MDBox mb={3} textAlign="right">
                <IconButton
                  size="small"
                  variant="contained"
                  onClick={handleOpenAttendanceMenu}
                >
                  <MDTypography variant="h7" color="secondary" mr={1}>
                    {getRangeDescription(attendanceDashBoardRange)}
                  </MDTypography>

                  <Icon>filter_list</Icon>
                </IconButton>
              </MDBox>

              {attendanceProcessing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}
              {!attendanceProcessing && (
                <ReportsBarChart
                  color="info"
                  title="Attendance Count"
                  description="Students attendance count"
                  date={getRangeDescription(attendanceDashBoardRange)}
                  chart={attendanceStatsData}
                />
              )}
            </Grid>

            {/* Line Report */}
            <Grid item xs={12} md={6}>
              <MDBox mb={3} textAlign="right">
                <IconButton
                  size="small"
                  variant="contained"
                  id="paidMenu"
                  onClick={handleOpenAmountMenu}
                >
                  <MDTypography variant="h7" color="secondary" mr={1}>
                    {getRangeDescription(paidAmountDashBoardRange)}
                  </MDTypography>

                  <Icon>filter_list</Icon>
                </IconButton>
              </MDBox>

              {paidProcessing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {!paidProcessing && (
                <ReportsLineChart
                  color="success"
                  title="Revenue"
                  description="Paid amount in $"
                  date={getRangeDescription(paidAmountDashBoardRange)}
                  chart={paidAmoutStatsData}
                />
              )}
            </Grid>

            {/* Attendance & Paid Stats Report */}
            <Grid item xs={12} md={6}>
              {attendanceProcessing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {!attendanceProcessing && (
                <Card sx={{ height: "100%" }}>
                  <MDBox
                    pt={2}
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Icon>pending_actions</Icon> Attendance Statistics
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                    >
                      <TwoGridCard
                        description="Max Count"
                        price={attendanceStats.max_count}
                        hint={
                          HintTexts.maxAttendanceCount.definition +
                          " " +
                          HintTexts.maxAttendanceCount.impact
                        }
                      />
                      <TwoGridCard
                        description="Mean Count"
                        price={attendanceStats.mean_count}
                        hint={
                          HintTexts.meanAttendanceCount.definition +
                          " " +
                          HintTexts.meanAttendanceCount.impact
                        }
                      />
                      <TwoGridCard
                        description="Median Count"
                        price={attendanceStats.median_count}
                        hint={
                          HintTexts.medianAttendanceCount.definition +
                          " " +
                          HintTexts.medianAttendanceCount.impact
                        }
                      />
                      <TwoGridCard
                        description="Min Count"
                        price={attendanceStats.min_count}
                        hint={
                          HintTexts.minAttendanceCount.definition +
                          " " +
                          HintTexts.minAttendanceCount.impact
                        }
                      />
                      <TwoGridCard
                        description="Mode Count"
                        price={attendanceStats.mode_count}
                        hint={
                          HintTexts.modeAttendanceCount.definition +
                          " " +
                          HintTexts.modeAttendanceCount.impact
                        }
                      />
                      <TwoGridCard
                        description="Total Count"
                        price={attendanceStats.total_count_of_attendance}
                        hint={
                          HintTexts.dailyAttendanceStats.definition +
                          " " +
                          HintTexts.dailyAttendanceStats.impact
                        }
                      />
                    </MDBox>
                  </MDBox>
                </Card>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              {paidProcessing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {!paidProcessing && (
                <Card sx={{ height: "100%" }}>
                  <MDBox
                    pt={2}
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Icon>monetization_on</Icon> Payment Amount Statistics
                    </MDTypography>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                    >
                      <TwoGridCard
                        description="Max Paid Amount"
                        price={"$" + paidAmountStats.max_paid_amount}
                        hint={
                          HintTexts.maxPaidAmount.definition +
                          " " +
                          HintTexts.maxPaidAmount.impact
                        }
                      />
                      <TwoGridCard
                        description="Mean Paid Amount"
                        price={"$" + paidAmountStats.mean_paid_amount}
                        hint={
                          HintTexts.meanPaidAmount.definition +
                          " " +
                          HintTexts.meanPaidAmount.impact
                        }
                      />
                      <TwoGridCard
                        description="Median Paid Amount"
                        price={"$" + paidAmountStats.median_paid_amount}
                        hint={
                          HintTexts.medianPaidAmount.definition +
                          " " +
                          HintTexts.medianPaidAmount.impact
                        }
                      />
                      <TwoGridCard
                        description="Min Paid Amount"
                        price={"$" + paidAmountStats.min_paid_amount}
                        hint={
                          HintTexts.minPaidAmount.definition +
                          " " +
                          HintTexts.minPaidAmount.impact
                        }
                      />
                      <TwoGridCard
                        description="Mode Paid Amount"
                        price={"$" + paidAmountStats.mode_paid_amount}
                        hint={
                          HintTexts.modePaidAmount.definition +
                          " " +
                          HintTexts.modePaidAmount.impact
                        }
                      />
                      <TwoGridCard
                        description="Standard Deviation of Paid Amount"
                        price={"$" + paidAmountStats.std_dev_paid_amount}
                        hint={
                          HintTexts.stdDevPaidAmount.definition +
                          " " +
                          HintTexts.stdDevPaidAmount.impact
                        }
                      />
                      <TwoGridCard
                        description="Total Paid Amount"
                        price={"$" + paidAmountStats.total_paid_amount}
                        hint={
                          HintTexts.totalPaidAmount.definition +
                          " " +
                          HintTexts.totalPaidAmount.impact
                        }
                      />
                    </MDBox>
                  </MDBox>
                </Card>
              )}
            </Grid>
          </Grid>
        </MDBox>
      )}
      {/* Api Error */}
      {!processing && apiError && <APIError />}
      {renderSnackBar}
      {renderAttendanceMenu}
      {renderAmountMenu}
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
