// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import APIError from "components/ApiError";
import dateFormatter from "components/Formatter/DateFormatter";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

import dayExtractor from "components/Formatter/DayExtractor";
import { useEffect, useState } from "react";
import parentAPIService from "services/parentAPI-service";

function PayerStudentPaymentDetails(props) {
  const { id, studentName } = props;

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = ["Date", "Method", "Amount"];

  useEffect(() => {
    fetchStudentPaymentData();
  }, [page, rowsPerPage]);

  const fetchStudentPaymentData = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const studentPaymentsList =
        await parentAPIService.getStudentPaymentHistory(
          id,
          rowsPerPage,
          page + 1
        );
      setTotal(studentPaymentsList.data.count);
      setRows(studentPaymentsList.data.results);
      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            {/* Heading */}
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Payment History
              </MDTypography>
            </MDBox>

            {/* Progress Bar */}
            {processing && (
              <MDBox pt={2} px={2}>
                <LinearProgress
                  color="info"
                  variant="indeterminate"
                  sx={{ overflow: "hidden" }}
                />
              </MDBox>
            )}

            {/* No Data */}
            {!processing && !apiError && total === 0 && (
              <MDBox pt={3} px={4} pb={5}>
                <MDTypography variant="h7" color="error">
                  No payment record found
                </MDTypography>
              </MDBox>
            )}

            {/* Table */}
            {!processing && total > 0 && (
              <MDBox pt={3} px={1} pb={5}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table>
                    {/* Header Columns */}
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell key={i}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Body Contents */}
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              {dayExtractor(row.payment_date)},{" "}
                              {dateFormatter(row.payment_date)}
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              <MDBadge
                                badgeContent={row.payment_method}
                                color={
                                  row.payment_method === "Cash"
                                    ? "success"
                                    : row.payment_method === "Zelle"
                                    ? "info"
                                    : row.payment_method === "Venmo"
                                    ? "primary"
                                    : row.payment_method === "Check"
                                    ? "secondary"
                                    : "dark"
                                }
                                variant="gradient"
                                size="sm"
                              />
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            <MDTypography variant="h7" fontWeight="bold">
                              $ {row.paid_amount}
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </MDBox>
            )}

            {/* Api Error */}
            {!processing && apiError && <APIError />}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default PayerStudentPaymentDetails;
