import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Icon from "@mui/material/Icon";
import Slide from "@mui/material/Slide";
import phoneNumberMask from "components/Formatter/PhoneMask";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef, useEffect, useState } from "react";
import payerAPIService from "services/payerAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AssociatePayerDialog(props) {
  const { open, onClose, id, studentName, student_UUID } = props;

  // Search Student By Name
  const [name, setName] = useState("");
  const [apiError, setApiError] = useState(false);
  const [searchProcessing, setSearchProcessing] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");

  const columns = ["Name", "Email", "Phone", "Action"];
  const RelationshipList = ["Parent", "Sibling", "Friend", "Other"];
  const [selectedPayerUUID, setselectedPayerUUID] = useState("");

  // For this form
  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    payer: "",
    student: "",
    relationship: "",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    payer: false,
    student: false,
    relationship: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
    setselectedPayerUUID("");
    setProcessing(false);
  };

  useEffect(() => {
    if (student_UUID) {
      setInputs({
        student: student_UUID,
      });

      fetchPayersList();
    }
  }, [student_UUID, open]);

  // Searching for Students
  const handleNameSearch = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchPayersList(); // Call the API after the debounce delay
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(debounceTimer); // Clear the timeout if the dependencies change
    };
  }, [name]);

  const fetchPayersList = async () => {
    setSearchProcessing(true);
    setApiError(false);
    try {
      const response = await payerAPIService.getPayersList(
        rowsPerPage,
        page + 1,
        "",
        name,
        orderBy
      );
      setRows(response.data.results);
      setTotal(response.data.count);

      setSearchProcessing(false);
    } catch (error) {
      setApiError(true);
      setSearchProcessing(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectPayer = (payer) => {
    setselectedPayerUUID(payer.uuid);
    setInputs({
      ...inputs,
      payer: payer.uuid,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.payer || inputs.payer.length == 0) {
      setErrors({ ...errors, payer: true });
      return;
    } else {
      setErrors({ ...errors, payer: false });
    }

    if (!inputs.relationship || inputs.relationship.length == 0) {
      setErrors({ ...errors, relationship: true });
      return;
    } else {
      setErrors({ ...errors, relationship: false });
    }

    if (!inputs.student || inputs.student.length == 0) {
      setErrors({ ...errors, student: true });
      return;
    } else {
      setErrors({ ...errors, student: false });
    }

    // Now that everything is verified
    setProcessing(true);
    try {
      let payload = {
        payer: inputs.payer,
        student_UUID: inputs.student,
        relationship_type: inputs.relationship,
      };
      const response = await payerAPIService.associateStudent(payload);
      setProcessing(false);
      setInputs({ ...initialState });
      setselectedPayerUUID("");
      onClose("success");
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to associate the Payer.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Associate New Payer
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>
      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox p={2} shadow="md" mb={4}>
            {/* Payer Name */}
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Student :
                </MDTypography>
                <MDTypography
                  variant="h7"
                  color="text"
                  fontWeight="regular"
                  ml={1}
                >
                  {studentName}
                </MDTypography>
              </MDBox>
            </MDBox>

            {/* Relationship  */}
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
                gap={2}
              >
                <MDTypography
                  variant="h7"
                  color="text"
                  fontWeight="bold"
                  display="flex"
                  gap="3px"
                >
                  <span style={{ color: "red" }}>*</span> Relationship
                </MDTypography>
                <Select
                  id="relationshipSelector"
                  value={inputs.relationship}
                  name="relationship"
                  onChange={changeHandler}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "1.8rem",
                  }}
                  error={errors.relationship}
                >
                  {RelationshipList.map((relation, index) => (
                    <MenuItem key={relation} value={relation}>
                      {relation}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </MDBox>

            {/* //  Search Students */}
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="regular">
                <span style={{ color: "red" }}>*</span> Please use the search
                box below to find the payer
              </MDTypography>
              <MDInput
                placeholder="Search Payer by name..."
                value={name}
                size="medium"
                onChange={handleNameSearch}
                sx={{
                  width: "100%",
                  background: "white",
                  borderRadius: "6px",
                }}
                type="search"
                InputProps={{
                  startAdornment: (
                    <Icon sx={{ color: "gray" }} fontSize="small">
                      search
                    </Icon>
                  ),
                }}
              />
            </MDBox>

            {/* Progress Bar */}
            {searchProcessing && (
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            )}

            {/* No Data */}
            {!searchProcessing && !apiError && total == 0 && (
              <MDBox>
                <MDTypography variant="h7" color="error">
                  No payer found
                </MDTypography>
              </MDBox>
            )}

            {/* Students */}
            {!searchProcessing && !apiError && total > 0 && (
              <MDBox pb={5}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table>
                    {/* Header Columns */}
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell key={i}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Body Contents */}
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <MDTypography
                              variant="h7"
                              fontWeight="bold"
                              color="text"
                            >
                              {row.first_name} {row.last_name}
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            <MDTypography
                              variant="h7"
                              fontWeight="regular"
                              color="text"
                            >
                              {row.email}
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            {row?.phone && (
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="text"
                              >
                                {phoneNumberMask(row.phone)}
                              </MDTypography>
                            )}
                          </TableCell>

                          <TableCell>
                            {/* If the student is not selected */}
                            {selectedPayerUUID != row?.uuid && (
                              <MDButton
                                variant="contained"
                                color="info"
                                size="small"
                                onClick={() => selectPayer(row)}
                              >
                                Select
                              </MDButton>
                            )}
                            {/* If the student is selected */}
                            {selectedPayerUUID === row?.uuid && (
                              <Icon fontSize="small" sx={{ color: "green" }}>
                                check_circle_outline
                              </Icon>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                  />
                </TableContainer>
              </MDBox>
            )}
          </MDBox>
        </MDBox>

        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AssociatePayerDialog;
