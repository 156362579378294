// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon, Tooltip } from "@mui/material";

function TwoGridCard({ description, id, price, hint, noGutter = false }) {
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography display="block" variant="button" fontWeight="regular">
          {description}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="button" fontWeight="regular" color="text">
          {price}
        </MDTypography>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          ml={3}
          sx={{ cursor: "pointer" }}
        >
          <Tooltip title={hint}>
            <Icon fontSize="small">help_outline</Icon>
          </Tooltip>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the TwoGridCard
TwoGridCard.propTypes = {
  noGutter: PropTypes.bool,
};

export default TwoGridCard;
