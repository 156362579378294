import { useEffect, useRef, useState } from "react";

// @mui material components
import { CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";

// Authentication layout components
import BasicLayout from "../../layouts/authentication/components/BasicLayout";

// Images
import bgImage from "../../assets/images/parent_bg5.jpg";

import colors from "assets/theme/base/colors";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "services/auth-service";
import { loginSuccess } from "../../redux/actions/actions";

function ParentLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const numberOfDigits = 6;

  const [currentStep, setCurrentStep] = useState(1);
  const [user, setUser] = useState({});
  const [credentialsErros, setCredentialsError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  // For OTP Box
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);

  const [processing, setProcessing] = useState(false);
  const [otpInvalid, setOTPInvalid] = useState(true);

  const [inputs, setInputs] = useState({
    phone: "",
    last_name: "",
  });

  const [errors, setErrors] = useState({
    phone: false,
    last_name: false,
  });

  const addUserHandler = (newUser) => setUser(newUser);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    // check rememeber me?
    e.preventDefault();

    if (!inputs.last_name || inputs.last_name.trim().length == 0) {
      setErrors({ ...errors, last_name: true });
      return;
    } else {
      setErrors({ ...errors, last_name: false });
    }

    if (!inputs.phone || inputs.phone.trim().length != 10) {
      setErrors({ ...errors, phone: true });
      return;
    } else {
      setErrors({ ...errors, phone: false });
    }

    const userDetails = { phone: inputs.phone, last_name: inputs.last_name };
    addUserHandler(userDetails);

    setProcessing(true);
    // Now Call In API To Authenticate
    try {
      const response = await AuthService.parentlogin(userDetails);

      // Go to OTP Code Screen
      setCurrentStep(2);
      setProcessing(false);
    } catch (res) {
      if (res?.response?.data?.error) {
        setCredentialsError(res.response.data.error);
      } else {
        setCredentialsError("Something went wrong.");
      }
      setProcessing(false);
    }

    return () => {
      setInputs({
        phone: "",
        last_name: "",
      });

      setErrors({
        phone: false,
        last_name: false,
      });
    };
  };

  const otpHandler = async (e) => {
    // check rememeber me?
    e.preventDefault();

    const userDetails = {
      phone: inputs.phone,
      last_name: inputs.last_name,
      token: otp.join(""),
    };
    addUserHandler(userDetails);

    setProcessing(true);
    // Now Call In API To Authenticate
    try {
      const response = await AuthService.verifyParentOTP(userDetails);
      // Dispatch Login Success
      dispatch(loginSuccess(response.data));
      setCurrentStep(1);
      setProcessing(false);

      // Navigate to home screen
      navigate("/");
    } catch (res) {
      if (res?.response?.data?.error) {
        setCredentialsError(res.response.data.error);
      } else {
        setCredentialsError("Something went wrong.");
      }
      setProcessing(false);
    }

    return () => {
      setInputs({
        phone: "",
        last_name: "",
      });

      setErrors({
        phone: false,
        last_name: false,
      });

      setOtp(new Array(numberOfDigits).fill(""));
    };
  };

  // For OTP COde
  function handleOTPChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  useEffect(() => {
    let joinOTP = otp.join("");
    if (joinOTP.length == 6) {
      setOTPInvalid(false);
    } else {
      setOTPInvalid(true);
    }
  }, [otp]);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="secondary"
          borderRadius="xl"
          coloredShadow="info"
          mx={2}
          mt={-4}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Parent Login
          </MDTypography>
        </MDBox>

        {/* Step 1, last name and phone number */}
        {currentStep == 1 && (
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              component="form"
              role="form"
              method="POST"
              onSubmit={submitHandler}
            >
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Last Name"
                  fullWidth
                  value={inputs.last_name}
                  name="last_name"
                  onChange={changeHandler}
                  error={errors.last_name}
                />
                {errors.last_name && (
                  <MDTypography variant="caption" color="error">
                    Last Name is required
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Phone"
                  fullWidth
                  name="phone"
                  value={inputs.phone}
                  onChange={changeHandler}
                  error={errors.phone}
                />
                {errors.phone && (
                  <MDTypography variant="caption" color="error">
                    Phone number must be exactly 10 digits.
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  disabled={processing}
                >
                  sign in
                  {processing && (
                    <CircularProgress
                      size={14}
                      sx={{ marginLeft: 1 }}
                      color="warning"
                    />
                  )}
                </MDButton>
              </MDBox>
              {credentialsErros && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="medium"
                >
                  {credentialsErros}
                </MDTypography>
              )}
            </MDBox>

            {/* Parent Login */}
            <MDBox mt={2}>
              <MDTypography variant="h8" fontWeight="medium">
                Not a parent?{" "}
                <Link to={`/`} style={{ color: colors.info.main }}>
                  Staff Login
                </Link>
              </MDTypography>
            </MDBox>
          </MDBox>
        )}

        {/* Step 2 OTP Code */}
        {currentStep == 2 && (
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              component="form"
              role="form"
              method="POST"
              onSubmit={otpHandler}
            >
              <MDTypography variant="h8" fontWeight="medium" color="text">
                Please enter the 6-Digit verification code that was sent to your
                phone number.
              </MDTypography>
              {/* OTP Code Box */}
              <MDBox
                mt={1}
                mb={2}
                display="flex"
                gap={2}
                sx={{
                  alignItems: "center",
                  placeContent: "center",
                }}
              >
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    value={digit}
                    maxLength={1}
                    onChange={(e) => handleOTPChange(e.target.value, index)}
                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                    ref={(reference) =>
                      (otpBoxReference.current[index] = reference)
                    }
                    style={{
                      height: "3em",
                      width: "11%",
                      placeItems: "center",
                      justifyContent: "center",
                    }}
                  />
                ))}
              </MDBox>

              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  disabled={processing || otpInvalid}
                >
                  Validate
                  {processing && (
                    <CircularProgress
                      size={14}
                      sx={{ marginLeft: 1 }}
                      color="warning"
                    />
                  )}
                </MDButton>
              </MDBox>
              {credentialsErros && (
                <MDTypography
                  variant="caption"
                  color="error"
                  fontWeight="medium"
                >
                  {credentialsErros}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        )}
      </Card>
    </BasicLayout>
  );
}

export default ParentLogin;
