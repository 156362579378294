import {
  Grid,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CreativeLineLogo from "assets/logos/creativelinelogo.png";
import dateFormatter from "components/Formatter/DateFormatter";
import dayExtractor from "components/Formatter/DayExtractor";
import defaultconvertTimeTo12HourFormat from "components/Formatter/HourMinuteFormatter";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import attendanceAPIService from "services/attendanceAPI-service";
import paymentAPIService from "services/paymentAPI-service";
import studentAPIService from "services/studentAPI-service";
import AddPaymentDialog from "../paymentDetails/addPayment";
import DisplayBox from "./ReportDisplayBox";

function StudentReports(props) {
  const printRef = useRef(null);
  const { id, studentName } = props;
  const [mostRecentAttendance, setMostRecentAttendance] = useState([]);
  const [mostRecentPaid, setMostRecentPaid] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);
  const [studentUnpaidDetails, setStudentUnpaidDetails] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [attendanceProcessing, setAttendanceProcessing] = useState(true);
  const [paymentProcessing, setPaymentProcessing] = useState(true);
  const [unpaidProcessing, setUnpaidProcessing] = useState(true);
  const [showLogo, setShowLogo] = useState(false);

  //Snack Bar
  const [addPaymentDialogOpen, setAddPaymentDialogOpen] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const columns = ["Date", "# of Classes", "Class Type", "Cost"];

  useEffect(() => {
    getMostRecentAttendance();
    getMostRecentPayment();
    getStudentInformation();
    getStudentUnpaidClasses();
  }, []);

  const getStudentInformation = async () => {
    setProcessing(true);

    try {
      const studenDetailsResponse =
        await studentAPIService.getStudentInformation(id);
      setStudentDetails(studenDetailsResponse.data);
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
    }
  };

  const getMostRecentAttendance = async () => {
    setAttendanceProcessing(true);
    try {
      const mostRecentAttendance =
        await attendanceAPIService.getMostRecentAttendance(id);
      setMostRecentAttendance(mostRecentAttendance.data);
      setAttendanceProcessing(false);
    } catch (error) {
      setAttendanceProcessing(false);
    }
  };

  const getMostRecentPayment = async () => {
    setPaymentProcessing(true);
    try {
      const mostRecentPayment = await paymentAPIService.getMostRecentPayment(
        id
      );
      setMostRecentPaid(mostRecentPayment.data);
      setPaymentProcessing(false);
    } catch (error) {
      setPaymentProcessing(false);
    }
  };

  const getStudentUnpaidClasses = async () => {
    setUnpaidProcessing(true);
    try {
      const unpaidData = await studentAPIService.getStudentUnpaidInformation(
        id
      );
      setStudentUnpaidDetails(unpaidData.data);
      setUnpaidProcessing(false);
    } catch (error) {
      setUnpaidProcessing(false);
    }
  };

  const handleAddPayment = (student) => {
    setAddPaymentDialogOpen(true);
  };

  const handleAddPaymentClose = (response) => {
    if (response === "Close") {
      setAddPaymentDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Payment record has been added.");
      openSnackBar();
      getStudentInformation();
      getMostRecentPayment();
      getStudentUnpaidClasses();
    }
    setAddPaymentDialogOpen(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${studentName}_Report`,
    onBeforeGetContent: () => {
      setShowLogo(true);
      return Promise.resolve();
    },
    onAfterPrint: () => {
      setShowLogo(false); // Hide the logo after printing or canceling
    },
  });

  useEffect(() => {
    // Listen for print cancelation or completion
    window.onafterprint = () => {
      setShowLogo(false); // Hide the logo after print is done or canceled
    };

    return () => {
      window.onafterprint = null; // Clean up the event listener when component unmounts
    };
  }, []);

  const renderAddPaymentDialog = (
    <AddPaymentDialog
      studentId={id}
      studentName={studentName}
      open={addPaymentDialogOpen}
      onClose={handleAddPaymentClose}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <MDBox pt={2} pb={3} id="reportContainer">
      {/* Print Button */}
      <MDBox textAlign="right" pb={3}>
        <MDButton onClick={handlePrint} color="info">
          <Icon sx={{ marginRight: 2 }}>print</Icon> Print
        </MDButton>
      </MDBox>

      <Grid container spacing={2} ref={printRef}>
        {/* Left Side - Most Recent */}
        <Grid item xs={12} lg={8}>
          <MDBox sx={{ height: "100%" }}>
            {/* Heading */}
            <MDBox alignItems="center" display="flex" gap={2} pt={3} px={2}>
              <MDTypography
                variant="h4"
                fontWeight="regular"
                textTransform="capitalize"
              >
                Report for <b>{studentName}</b>
              </MDTypography>

              {showLogo && (
                <img
                  src={CreativeLineLogo}
                  alt="CreativeLineLogo"
                  height={100}
                  width={120}
                  style={{ objectFit: "contain", marginLeft: "auto" }}
                />
              )}
            </MDBox>
            {/* Contents */}
            <MDBox pt={3} pb={2} px={2}>
              {/* Most Recent Attendance */}
              <MDBox mb={2}>
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Most Recent Attendance
                </MDTypography>
              </MDBox>
              {!attendanceProcessing && mostRecentAttendance?.Class_type && (
                <MDBox p={0} m={0}>
                  <DisplayBox
                    color="info"
                    icon="event_available"
                    name={`${mostRecentAttendance.Class_type} Attendance`}
                    description={`${dateFormatter(
                      mostRecentAttendance.date
                    )}, at ${defaultconvertTimeTo12HourFormat(
                      mostRecentAttendance.start_time
                    )} - ${defaultconvertTimeTo12HourFormat(
                      mostRecentAttendance.end_time
                    )}`}
                    value={`$ ${mostRecentAttendance.cost_of_this_attendance}`}
                  />
                </MDBox>
              )}
              {!attendanceProcessing && !mostRecentAttendance?.Class_type && (
                <MDBox p={0} m={0}>
                  <MDTypography variant="h7" color="error">
                    No attendance information available at this time
                  </MDTypography>
                </MDBox>
              )}

              {/* Most Recent Payment */}
              <MDBox mb={2} mt={2}>
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Most Recent Payment
                </MDTypography>
              </MDBox>

              {!paymentProcessing && mostRecentPaid?.payment_method && (
                <MDBox p={0} m={0}>
                  <DisplayBox
                    color="success"
                    icon="attach_money"
                    name={`${mostRecentPaid.payment_method.payment_method} Payment`}
                    description={`${dateFormatter(
                      mostRecentPaid.payment_date
                    )}`}
                    value={`$ ${mostRecentPaid.paid_amount}`}
                  />
                </MDBox>
              )}
              {!paymentProcessing && !mostRecentPaid?.payment_method && (
                <MDBox p={0} m={0}>
                  <MDTypography variant="h7" color="error">
                    No payment information available at this time
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        {/* Right Side - UnPaid Balence*/}
        <Grid item xs={12} lg={4}>
          <MDBox
            variant="contained"
            sx={{ height: "100%" }}
            shadow="sm"
            coloredShadow="dark"
            borderRadius="xl"
            bgColor="grey-200"
          >
            {/* Heading */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={3}
              px={2}
            >
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
              >
                Pending Balance
              </MDTypography>
            </MDBox>
            {/* Contents */}
            {!processing && studentDetails?.id && (
              <MDBox pt={3} pb={2} px={2}>
                <MDBox p={0} m={0}>
                  <DisplayBox
                    color={
                      studentDetails.unpaid_amount <= 0 ? "success" : "error"
                    }
                    icon="price_change"
                    name={`Current Balance`}
                    description=""
                    value={`$ ${studentDetails.unpaid_amount}`}
                  />
                  {/* Negative Indicator */}
                  {studentDetails.unpaid_amount < 0 && (
                    <MDTypography
                      variant="h7"
                      fontWeight="regular"
                      color="secondary"
                    >
                      -ve indicates a credit amount
                    </MDTypography>
                  )}
                  {/* If balance is >0 */}
                  {studentDetails.unpaid_amount > 0 && (
                    <MDBox textAlign="center">
                      <MDButton
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleAddPayment}
                      >
                        Make A Payment
                      </MDButton>
                    </MDBox>
                  )}

                  {studentDetails.unpaid_amount === 0 && (
                    <MDTypography variant="h7" color="success">
                      No Payment Due at this time
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </Grid>

        {/* Unpaid Details */}
        <Grid item xs={12}>
          <MDBox>
            {/* Heading */}
            <MDBox display="flex flex-col" pt={3} px={2}>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
              >
                Unpaid Classes (
                {!unpaidProcessing && studentUnpaidDetails && (
                  <span>{studentUnpaidDetails.unpaid_classes}</span>
                )}
                )
              </MDTypography>
              <MDTypography
                variant="h7"
                fontWeight="regular"
                fontStyle="italic"
                color="secondary"
              >
                {!unpaidProcessing && studentUnpaidDetails && (
                  <span>{studentUnpaidDetails.message}</span>
                )}
              </MDTypography>
            </MDBox>
            {/* Contents */}
            <MDBox pt={3} pb={2} px={2}>
              {!unpaidProcessing &&
                studentUnpaidDetails &&
                studentUnpaidDetails?.attendance?.length > 0 && (
                  <TableContainer
                    component={Paper}
                    sx={{ boxShadow: "none", width: "100%" }}
                  >
                    <Table>
                      {/* Header Columns */}
                      <TableHead>
                        <TableRow>
                          {columns.map((column, i) => (
                            <TableCell key={i}>{column}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* Body Contents */}
                      <TableBody>
                        {studentUnpaidDetails.attendance.map((row, i) => (
                          <TableRow key={"unpaidClass" + i}>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {dateFormatter(row.date)} (
                                {dayExtractor(row.date)})
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {row.Number_of_classes}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {row.Class_type}
                              </MDTypography>
                            </TableCell>
                            {/* <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {defaultconvertTimeTo12HourFormat(
                                  row.start_time
                                )}
                              </MDTypography>
                            </TableCell> */}
                            {/* <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {defaultconvertTimeTo12HourFormat(row.end_time)}
                              </MDTypography>
                            </TableCell> */}
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                $ {row.cost_of_this_attendance}
                              </MDTypography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      {renderAddPaymentDialog}
      {renderSnackBar}
    </MDBox>
  );
}

export default StudentReports;
