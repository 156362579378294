// Material Dashboard 2 React example components
import { Card, Icon } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MDBox from "components/MDBox";
import Footer from "../../components/Footer";

import MDSnackbar from "components/MDSnackbar";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import APIError from "components/ApiError";
import defaultconvertTimeTo12HourFormat from "components/Formatter/HourMinuteFormatter";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState, useRef } from "react";
import studentAPIService from "services/studentAPI-service";

import BGImage from "assets/images/bg9.jpg";
import DeleteConfirmationDialog from "components/Dialog/DeleteDialog";
import dateFormatter from "components/Formatter/DateFormatter";
import MDButton from "components/MDButton";
import attendanceAPIService from "services/attendanceAPI-service";
function TakeAttendance() {
  const today = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-");

  const [currentDay, setCurrentDay] = useState(null);
  const [status, setStatus] = useState("Active");
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [attendanceProcessing, setAttendanceProcessing] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [searchName, setSearchName] = useState("");

  const [attendanceOnHold, setAttendanceOnHold] = useState([]);
  const [actualData, setActualData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const [takeAttendanceDialogOpen, setTakeAttendanceDialogOpen] =
    useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [attendanceItem, setAttendanceItem] = useState("");

  const [additionalMember, setAdditionalMember] = useState([]);
  const [additionalMemberLoading, setAdditionalMemberLoading] = useState(false);

  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);
  const debounceTimeoutRef = useRef(null);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const tempCurrentDate = new Date();
    const dayIndex = tempCurrentDate.getDay();
    setCurrentDay(dayIndex);

    fetchStudentSchedulesData(dayIndex);
    fetchAttendanceOnHoldData();
  }, []);

  const fetchStudentSchedulesData = async (day) => {
    setProcessing(true);
    setApiError(false);
    try {
      const response = await studentAPIService.getStudentsScheduleListByDay(
        day,
        status
      );
      setTotal(response.data.count);
      setActualData(response.data.results);
      setFilteredData(response.data.results);
      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleNameSearch = (e) => {
    setSearchName(e.target.value);
    // Now filter all the data
    if (e.target.value) {
      let tempFilterData = actualData.filter((data) => {
        const concatFirstLast =
          data.student.first_name.toLowerCase() +
          " " +
          data.student.last_name.toLowerCase();

        const concatLastFirst =
          data.student.last_name.toLowerCase() +
          " " +
          data.student.first_name.toLowerCase();
        if (
          concatFirstLast.includes(e.target.value.toLowerCase()) ||
          concatLastFirst.includes(e.target.value.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setFilteredData(tempFilterData);

      // Spin up a new backend search
      if (tempFilterData.length == 0) {
        // Clear any existing timeout
        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new timeout to debounce the API call
        debounceTimeoutRef.current = setTimeout(() => {
          searchForOtherStudents(e.target.value.toLowerCase());
        }, 500); // Adjust the debounce time as needed (500ms here)
      }
    } else {
      setFilteredData(actualData);
    }
  };

  const searchForOtherStudents = async (searchName) => {
    setAdditionalMemberLoading(true);
    try {
      const response = await studentAPIService.getAdditionalStudentsForSchedule(
        searchName
      );
      setAdditionalMemberLoading(false);
      setAdditionalMember(response.data.results);
    } catch (error) {
      setAdditionalMemberLoading(false);
    }
  };

  const handleTakeAttendance = async (student) => {
    if (!attendanceForStudentExists(student)) {
      setAttendanceItem(student);

      if (process.env.REACT_APP_ATTENDANCE_CONFIRM_DIALOG === "YES") {
        // Now open dialog box
        setDialogMessage(
          "Hello " +
            student.student.first_name +
            " " +
            student.student.last_name +
            ", would you like to mark your attendance?"
        );
        setTakeAttendanceDialogOpen(true);
      } else {
        updateAttendaceRecord(student);
      }
    }
  };

  const handleConfirmTakeAttendance = async (response) => {
    if (response === "No") {
      setAttendanceItem(null);
      setDialogMessage("");
      setTakeAttendanceDialogOpen(false);
    } else {
      updateAttendaceRecord(attendanceItem);
    }
  };

  const updateAttendaceRecord = async (studentInfo) => {
    let payload = {
      date: today,
      start_time: studentInfo.start_time,
      end_time: studentInfo.end_time,
      student: studentInfo.student.id,
    };

    setAttendanceProcessing(true);
    try {
      const response = await attendanceAPIService.addAttendanceOnHold(payload);
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Attendance Record has been marked.");
      openSnackBar();

      // Fetch new attendance data
      fetchAttendanceOnHoldData();
      setAttendanceProcessing(false);

      // Restore attendance dialog
      setAttendanceItem(null);
      setDialogMessage("");
      setTakeAttendanceDialogOpen(false);
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to add the Attendance Record.");
      openSnackBar();
      setAttendanceProcessing(false);

      // Restore attendance dialog
      setAttendanceItem(null);
      setDialogMessage("");
      setTakeAttendanceDialogOpen(false);
    }
  };

  const fetchAttendanceOnHoldData = async () => {
    try {
      const response = await attendanceAPIService.getAttendanceOnHold(today);
      setAttendanceOnHold(response.data.results);
    } catch (error) {}
  };

  // Check if this student attendance already exists
  const attendanceForStudentExists = (row) => {
    let indexId = attendanceOnHold.findIndex((e) => {
      return e.student.id === row.student.id;
    });

    if (indexId < 0) {
      return false;
    } else {
      return true;
    }
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  const renderConfirmDialog = (
    <DeleteConfirmationDialog
      open={takeAttendanceDialogOpen}
      onClose={handleConfirmTakeAttendance}
      message={dialogMessage}
    />
  );

  return (
    <MDBox p={4}>
      <MDBox pt={10} pb={10} overflow="auto">
        <Card>
          {/* Heading */}
          <MDBox
            mx={2}
            mt={-8}
            py={6}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="xxl"
            coloredShadow="info"
            height="120px"
            sx={{
              backgroundImage: ({
                functions: { rgba, linearGradient },
                palette: { gradients },
              }) =>
                `${linearGradient(
                  rgba(gradients.info.main, 0.1),
                  rgba(gradients.info.state, 0.1)
                )}, url(${BGImage})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          >
            <MDTypography variant="h4" color="info">
              Today's Schedule
            </MDTypography>
          </MDBox>

          {/* Search By Name */}
          <MDBox p={2} pt={4}>
            <MDInput
              placeholder="Search by first name..."
              size="medium"
              value={searchName}
              sx={{ width: 250, background: "white", borderRadius: "6px" }}
              type="search"
              onChange={handleNameSearch}
              InputProps={{
                startAdornment: (
                  <Icon sx={{ color: "gray" }} fontSize="small">
                    search
                  </Icon>
                ),
              }}
            />
          </MDBox>

          {/* Processing Bar */}
          {(processing || attendanceProcessing) && (
            <MDBox pt={2} px={2}>
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            </MDBox>
          )}

          {/* Students List */}
          {!processing && total > 0 && (
            <MDBox pt={3} px={1} pb={5}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table>
                  {/* Header Columns */}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key={"dayname"}
                        sx={{
                          minWidth: "16rem",
                          textAlign: "center",
                        }}
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="bold"
                          color="dark"
                        >
                          {days[currentDay]}, {dateFormatter(today)}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Body Contents */}
                  <TableBody>
                    <TableRow>
                      <TableCell key={"cell"}>
                        {filteredData.map((data, i) => (
                          <MDBox
                            key={data.id}
                            shadow="sm"
                            borderRadius="xl"
                            coloredShadow="light"
                            bgColor="grey-200"
                            p={2}
                            mb={2}
                            width="100%"
                            height="110px"
                            display="flex"
                            flexDirection="column"
                            position="relative"
                            sx={{
                              cursor: "pointer",
                              backgroundColor: attendanceForStudentExists(data)
                                ? "#88fb88"
                                : "",
                            }}
                            onClick={() => {
                              handleTakeAttendance(data);
                            }}
                          >
                            <MDTypography
                              variant="caption"
                              fontWeight="bold"
                              color="dark"
                              sx={{
                                position: "absolute",
                                right: 10,
                                top: 5,
                              }}
                            >
                              {days[currentDay].charAt(0)}
                              {days[currentDay].charAt(1)}
                            </MDTypography>

                            <MDTypography
                              variant="h6"
                              fontWeight="bold"
                              color="info"
                            >
                              {data.student.first_name} {data.student.last_name}
                            </MDTypography>

                            <MDTypography
                              variant="h7"
                              fontWeight="regular"
                              color="dark"
                            >
                              {" "}
                              Start Time&nbsp;:&nbsp;
                              {defaultconvertTimeTo12HourFormat(
                                data.start_time
                              )}
                            </MDTypography>
                            <MDTypography
                              variant="h7"
                              fontWeight="regular"
                              color="dark"
                            >
                              End Time&nbsp;&nbsp;&nbsp;:&nbsp;
                              {defaultconvertTimeTo12HourFormat(data.end_time)}
                            </MDTypography>
                          </MDBox>
                        ))}

                        {filteredData.length == 0 && (
                          <MDBox pt={3} px={1} pb={5}>
                            <MDTypography
                              variant="h5"
                              fontWeight="bold"
                              color="text"
                              textAlign="center"
                              mb={4}
                            >
                              No matching student found for today's schedule
                            </MDTypography>

                            {additionalMemberLoading && (
                              <LinearProgress
                                color="info"
                                variant="indeterminate"
                                sx={{ overflow: "hidden" }}
                              />
                            )}

                            {/* Additional Students that are not in todays schedule */}
                            {additionalMember.length > 0 && (
                              <MDTypography
                                variant="h5"
                                fontWeight="bold"
                                color="text"
                                textAlign="left"
                                mb={2}
                              >
                                Additional students who are not on today's
                                schedule
                              </MDTypography>
                            )}

                            {additionalMember.map((data, i) => (
                              <MDBox
                                key={data.id}
                                shadow="sm"
                                borderRadius="xl"
                                coloredShadow="light"
                                bgColor="grey-200"
                                p={2}
                                mb={2}
                                width="100%"
                                height="110px"
                                display="flex"
                                flexDirection="column"
                                position="relative"
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: attendanceForStudentExists(
                                    data
                                  )
                                    ? "#88fb88"
                                    : "",
                                }}
                                onClick={() => {
                                  handleTakeAttendance(data);
                                }}
                              >
                                <MDTypography
                                  variant="caption"
                                  fontWeight="bold"
                                  color="dark"
                                  sx={{
                                    position: "absolute",
                                    right: 10,
                                    top: 5,
                                  }}
                                >
                                  {days[currentDay].charAt(0)}
                                  {days[currentDay].charAt(1)}
                                </MDTypography>

                                <MDTypography
                                  variant="h6"
                                  fontWeight="bold"
                                  color="info"
                                >
                                  {data.student.first_name}{" "}
                                  {data.student.last_name}
                                </MDTypography>

                                <MDTypography
                                  variant="h7"
                                  fontWeight="regular"
                                  color="dark"
                                >
                                  {" "}
                                  Start Time&nbsp;:&nbsp;
                                  {defaultconvertTimeTo12HourFormat(
                                    data.start_time
                                  )}
                                </MDTypography>
                                <MDTypography
                                  variant="h7"
                                  fontWeight="regular"
                                  color="dark"
                                >
                                  End Time&nbsp;&nbsp;&nbsp;:&nbsp;
                                  {defaultconvertTimeTo12HourFormat(
                                    data.end_time
                                  )}
                                </MDTypography>
                              </MDBox>
                            ))}
                          </MDBox>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>
          )}

          {/* Api Error */}
          {!processing && apiError && <APIError />}
        </Card>
      </MDBox>
      {renderSnackBar}
      {renderConfirmDialog}
      <Footer />
    </MDBox>
  );
}

export default TakeAttendance;
