// @mui material components

// Material Dashboard 2 React components
// Material Dashboard 2 React example components
import APIError from "components/ApiError";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
// @mui material components
import { Grid } from "@mui/material";
import payerAPIService from "services/payerAPI-service";

// Material Dashboard 2 React components
import avatar from "assets/images/payer_avatar.png";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

import { Icon, LinearProgress, Tooltip } from "@mui/material";
import AssociatePayerDialog from "appStack/payers/associatePayers";
import DeleteConfirmationDialog from "components/Dialog/DeleteDialog";
import phoneNumberMask from "components/Formatter/PhoneMask";
import { Link } from "react-router-dom";

function StudentsAssociatedPayers(props) {
  let { id, studentName, student_UUID } = props;

  const [associatedPayers, setAssociatedPayers] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  // For delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState("");

  const [associatePayerDialogOpen, setAssociatePayerDialogOpen] =
    useState(false);

  useEffect(() => {
    fetchPayerInformation();
  }, []);

  const fetchPayerInformation = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const response = await payerAPIService.getAssociatedPayersForStudent(
        student_UUID
      );
      setAssociatedPayers(response.data);
      setProcessing(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleAssociatePayer = () => {
    setAssociatePayerDialogOpen(true);
  };

  const handleAssociatePayerDialogClose = (response) => {
    if (response === "Close") {
      setAssociatePayerDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("New Student has been associated.");
      openSnackBar();
      fetchPayerInformation();
      setAssociatePayerDialogOpen(false);
    }
  };

  const removePayer = (payer) => {
    setDeleteItem(payer);
    setDialogMessage(
      "Are you sure you want to remove this payer from the student?"
    );
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = async (response) => {
    if (response === "No") {
      setDeleteItem(null);
      setDeleteDialogOpen(false);
    } else {
      setDeleteProcessing(true);

      try {
        let payload = {
          payer: deleteItem.uuid,
          student_UUID: student_UUID,
        };

        const deleteResponse = await payerAPIService.detachStudent(payload);

        setSnackBarColor("success");
        setSnackBarIcon("check");
        setSnackBarTitle("Success");
        setSnackBarMessage("Payer has been removed from the student.");
        openSnackBar();

        fetchPayerInformation();
        setDeleteProcessing(false);
        setDeleteItem(null);
        setDeleteDialogOpen(false);
      } catch (error) {
        setSnackBarColor("error");
        setSnackBarIcon("warning");
        setSnackBarTitle("Error");
        setSnackBarMessage("Failed to remove the Payer from the Student.");
        openSnackBar();
        setDeleteProcessing(false);
        setDeleteDialogOpen(false);
      }
    }
  };

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const renderAssociatePayerDialog = (
    <AssociatePayerDialog
      open={associatePayerDialogOpen}
      onClose={handleAssociatePayerDialogClose}
      id={id}
      studentName={studentName}
      student_UUID={student_UUID}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  const renderStudentDetach = (
    <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleDeleteClose}
      message={dialogMessage}
    />
  );

  return (
    <MDBox pb={3}>
      {/* Displaying Payer Information */}

      <MDBox position="relative" mb={5}>
        {/* Progress Bar */}
        {processing && (
          <MDBox pt={2} px={2}>
            <LinearProgress
              color="info"
              variant="indeterminate"
              sx={{ overflow: "hidden" }}
            />
          </MDBox>
        )}

        {/* Display Associated Students */}

        {!processing && !apiError && (
          <MDBox mt={5} width="100%">
            {/* Heading */}
            <MDBox
              justifyContent="space-between"
              display="flex"
              flexWrap="wrap"
              gap={2}
              pt={3}
              px={2}
            >
              <MDTypography
                variant="h5"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Associated Payers
              </MDTypography>

              <MDButton
                variant="contained"
                color="dark"
                size="small"
                type="button"
                onClick={handleAssociatePayer}
              >
                Associate Payer
              </MDButton>
            </MDBox>

            {/* No Associated Students */}
            {associatedPayers?.results?.length == 0 && (
              <MDBox pt={3} px={2} pb={5}>
                <MDTypography variant="h7" color="error">
                  No payer has been associated with the student
                </MDTypography>
              </MDBox>
            )}

            {/* Associated Students */}
            {associatedPayers?.results?.length > 0 && (
              <MDBox pt={3} px={2} pb={5}>
                {/* Grid Display */}
                <Grid container spacing={2}>
                  {associatedPayers?.results.map((payer, index) => (
                    <Grid item xs={12} lg={6} key={"Payer" + index}>
                      {/* Outer Box */}
                      <MDBox
                        position="relative"
                        variant="contained"
                        sx={{ height: "100%" }}
                        shadow="sm"
                        coloredShadow="dark"
                        borderRadius="xl"
                        bgColor="grey-200"
                        px={2}
                        py={2}
                      >
                        {/* Display Student Information here */}
                        {/* Student Avatar + Name */}
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <MDAvatar
                            src={avatar}
                            alt="profile-image"
                            size="xl"
                            shadow="sm"
                          />
                          {/* Name */}
                          <Tooltip title="View Payer Details">
                            <Link to={`/payers/${payer.uuid}`}>
                              <MDTypography
                                variant="h6"
                                color="text"
                                sx={{ cursor: "pointer" }}
                              >
                                {payer.first_name} {payer.last_name}
                              </MDTypography>
                            </Link>
                          </Tooltip>
                          {/* Email */}
                          {payer?.email && (
                            <MDTypography variant="h7" color="text">
                              {payer.email}
                            </MDTypography>
                          )}
                          {/* Phone */}
                          {payer?.phone && (
                            <MDTypography variant="h8" color="text">
                              {phoneNumberMask(payer.phone)}
                            </MDTypography>
                          )}
                        </MDBox>

                        {/* Action */}
                        <MDBox position="absolute" right={2} top={1}>
                          <Tooltip title="Remove Payer">
                            <MDButton
                              iconOnly
                              color="error"
                              circular
                              size="small"
                              onClick={() => removePayer(payer)}
                            >
                              <Icon fontSize="small">delete_forever</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </MDBox>
            )}
          </MDBox>
        )}

        {/* API Error */}
        {!processing && apiError && <APIError />}

        {renderSnackBar}
        {renderAssociatePayerDialog}
        {renderStudentDetach}
        {/* API Error */}
        {!processing && apiError && <APIError />}
      </MDBox>
    </MDBox>
  );
}

export default StudentsAssociatedPayers;
