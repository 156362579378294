import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MobileStepper from "@mui/material/MobileStepper";
import Slide from "@mui/material/Slide";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { DaysNumberMap } from "assets/constants/Days";
import { UsStates } from "assets/constants/UsStates";
import colors from "assets/theme/base/colors";
import defaultconvertTimeTo12HourFormat from "components/Formatter/HourMinuteFormatter";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef, useEffect, useState } from "react";
import studentAPIService from "services/studentAPI-service";
import timeSlotAPIService from "services/timeSlotAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AddProspectStudent(props) {
  const daysNumberMap = DaysNumberMap;
  const [activeStep, setActiveStep] = useState(0);
  const [avaliableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [avaliableTimeSlotsProcessing, setAvaliableTimeSlotsProcessing] =
    useState(false);

  const { open, onClose } = props;

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    firstName: "",
    lastName: "",
    parent1FirstName: "",
    parent1LastName: "",
    email1: "",
    phone1: "",
    parent2FirstName: "",
    parent2LastName: "",
    email2: "",
    phone2: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    referredBy: "",
    note: "",
    status: "Prospective",

    // Student settings
    enter_art_contests: false,
    exhibit_artwork: false,
    receive_emails: false,
    receive_text: false,
    sell_artwork_permission: false,
    payment_method: 1,

    // Schedule
    startTime: "",
    endTime: "",
    day: "",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,

    // Config
    enter_art_contests: false,
    exhibit_artwork: false,
    receive_emails: false,
    receive_text: false,
    sell_artwork_permission: false,
    payment_method: false,

    // Schedule
    startTime: false,
    endTime: false,
    day: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setActiveStep(0);
    setInputs({ ...initialState });
    setProcessing(false);
  };

  const showWarning = (message) => {
    setSnackBarColor("warning");
    setSnackBarIcon("check");
    setSnackBarTitle("Warning");
    setSnackBarMessage(message);
    openSnackBar();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.firstName) {
      setErrors({ ...errors, firstName: true });
      showWarning("Please input first name");
      return;
    } else {
      setErrors({ ...errors, firstName: false });
    }

    if (!inputs.lastName) {
      setErrors({ ...errors, lastName: true });
      showWarning("Please input last name");
      return;
    } else {
      setErrors({ ...errors, lastName: false });
    }

    // Check if everything has been input
    if (!inputs.startTime) {
      setErrors({ ...errors, startTime: true });
      showWarning("Please select the preferred Schedule");
      return;
    } else {
      setErrors({ ...errors, startTime: false });
    }

    if (!inputs.endTime) {
      setErrors({ ...errors, endTime: true });
      showWarning("Please select the preferred Schedule");
      return;
    } else {
      setErrors({ ...errors, endTime: false });
    }

    if (!inputs.day) {
      setErrors({ ...errors, day: true });
      showWarning("Please select the preferred Schedule");
      return;
    } else {
      setErrors({ ...errors, day: false });
    }

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!inputs.email1 || inputs.email1.trim().length === 0) {
      setErrors({ ...errors, email1: false });
    } else {
      if (!inputs.email1.trim().match(mailFormat)) {
        showWarning("Please input a valid email");
        setErrors({ ...errors, email1: true });
        return;
      }
    }

    if (!inputs.email2 || inputs.email2.trim().length === 0) {
      setErrors({ ...errors, email2: false });
    } else {
      if (!inputs.email2.trim().match(mailFormat)) {
        showWarning("Please input a valid email");
        setErrors({ ...errors, email2: true });
        return;
      }
    }

    // Now that everything is verified
    setProcessing(true);
    try {
      // First Create a brand new student and set status as propective
      let createStudentPayload = {
        first_name: inputs.firstName,
        last_name: inputs.lastName,
        parent_1_first_name: inputs.parent1FirstName,
        parent_1_last_name: inputs.parent1LastName,
        contact_1_email: inputs.email1,
        phone_1: inputs.phone1,
        parent_2_first_name: inputs.parent2FirstName,
        parent_2_last_name: inputs.parent2LastName,
        contact_2_email: inputs.email2,
        phone_2: inputs.phone2,
        address_1: inputs.address1,
        address_2: inputs.address2,
        city: inputs.city,
        state: inputs.state,
        zip_code: inputs.zip,
        referred_by: inputs.referredBy,
        note: inputs.note,
        status: inputs.status,
      };
      const response = await studentAPIService.addStudent(createStudentPayload);
      // From Response add extract the student id
      let studentId = response.data.id;
      // Using student Id configure the settings
      let studentConfigPayload = {
        student: studentId,
        enter_art_contests: inputs.enter_art_contests,
        exhibit_artwork: inputs.exhibit_artwork,
        receive_emails: inputs.receive_emails,
        receive_text: inputs.receive_text,
        sell_artwork_permission: inputs.sell_artwork_permission,
        payment_method: inputs.payment_method,
      };
      // Post a new config
      const configResponse = await studentAPIService.addNewStudentConfigDetails(
        studentConfigPayload
      );
      // Finally add in the preferred schedule to the student details
      let addSchedulePayload = {
        student: studentId,
        start_time: inputs.startTime,
        end_time: inputs.endTime,
        day: daysNumberMap.get(inputs.day), // Need to append number instead of the string
      };
      const scheduleResponse = await studentAPIService.addStudentSchedule(
        addSchedulePayload
      );

      setProcessing(false);
      setActiveStep(0);
      setInputs({ ...initialState });
      onClose("success");
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to add the Student.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleScheduleChange = (e) => {
    let selectedTimeSlot = avaliableTimeSlots
      .filter((slot) => (slot.id = e.target.value))
      .at(0);

    // Set the day, start, and end time
    setInputs({
      ...inputs,
      day: selectedTimeSlot.day,
      startTime: selectedTimeSlot.start_time,
      endTime: selectedTimeSlot.end_time,
    });
  };

  useEffect(() => {
    fetchAllAvailableTimeSlots();
  }, []);

  const fetchAllAvailableTimeSlots = async () => {
    setAvaliableTimeSlotsProcessing(true);

    try {
      const avaliableTimeSlots = await timeSlotAPIService.getAvaiableTimeSlots(
        100,
        1
      );
      setAvailableTimeSlots(avaliableTimeSlots.data.results);
      setAvaliableTimeSlotsProcessing(false);
    } catch (error) {
      setAvaliableTimeSlotsProcessing(false);
    }
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  const steps = [
    "Select master blaster campaign settings",
    "Create an ad group",
    "Create an ad",
  ];

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Register
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>
      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          my={2}
          shadow="sm"
          borderRadius="xxl"
          coloredShadow="info"
        >
          {/* Step 1 is student information */}
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            sx={{ boxShadow: "none" }}
          >
            {/* Student Details */}
            <Step>
              <StepLabel>Student Details</StepLabel>
            </Step>

            <Step>
              <StepLabel>Student Setttings</StepLabel>
            </Step>

            <Step>
              <StepLabel>Preferred Schedule</StepLabel>
            </Step>
          </Stepper>

          {/* Display Contents For Each Step */}
          {/* Step 0, student information */}
          {activeStep == 0 && (
            <MDBox variant="contained" p={2}>
              <MDTypography
                variant="h6"
                color="text"
                display="flex"
                columnGap={1}
                alignItems="center"
              >
                <Icon sx={{ color: "gray" }} fontSize="small">
                  person
                </Icon>
                General Information
              </MDTypography>
              <MDBox p={2} shadow="md" mb={4}>
                <MDBox display="flex" flexDirection="row" columnGap={2}>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      <span style={{ color: "red" }}>*</span> First Name
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.firstName}
                      name="firstName"
                      onChange={changeHandler}
                      error={errors.firstName}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      <span style={{ color: "red" }}>*</span> Last Name
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.lastName}
                      name="lastName"
                      onChange={changeHandler}
                      error={errors.lastName}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDTypography
                variant="h6"
                color="text"
                display="flex"
                columnGap={1}
                alignItems="center"
              >
                <Icon sx={{ color: "gray" }} fontSize="small">
                  family_restroom
                </Icon>
                Parent 1 Information
              </MDTypography>
              <MDBox p={2} shadow="md" mb={4}>
                <MDBox display="flex" flexDirection="row" columnGap={2}>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Parent First Name
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.parent1FirstName}
                      name="parent1FirstName"
                      onChange={changeHandler}
                      error={errors.parent1FirstName}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Parent Last Name
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.parent1LastName}
                      name="parent1LastName"
                      onChange={changeHandler}
                      error={errors.parent1LastName}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" flexDirection="row" columnGap={2}>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Email
                    </MDTypography>
                    <MDInput
                      type="email"
                      value={inputs.email1}
                      name="email1"
                      onChange={changeHandler}
                      error={errors.email1}
                      fullWidth
                    />
                  </MDBox>

                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Phone
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.phone1}
                      name="phone1"
                      onChange={changeHandler}
                      error={errors.phone1}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDTypography
                variant="h6"
                color="text"
                display="flex"
                columnGap={1}
                alignItems="center"
              >
                <Icon sx={{ color: "gray" }} fontSize="small">
                  family_restroom
                </Icon>
                Parent 2 Information
              </MDTypography>
              <MDBox p={2} shadow="md" mb={4}>
                <MDBox display="flex" flexDirection="row" columnGap={2}>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Parent First Name
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.parent2FirstName}
                      name="parent2FirstName"
                      onChange={changeHandler}
                      error={errors.parent2FirstName}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Parent Last Name
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.parent2LastName}
                      name="parent2LastName"
                      onChange={changeHandler}
                      error={errors.parent2LastName}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" flexDirection="row" columnGap={2}>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Email
                    </MDTypography>
                    <MDInput
                      type="email"
                      value={inputs.email2}
                      name="email2"
                      onChange={changeHandler}
                      error={errors.email2}
                      fullWidth
                    />
                  </MDBox>

                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Phone
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.phone2}
                      name="phone2"
                      onChange={changeHandler}
                      error={errors.phone2}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDTypography
                variant="h6"
                color="text"
                display="flex"
                columnGap={1}
                alignItems="center"
              >
                <Icon sx={{ color: "gray" }} fontSize="small">
                  home
                </Icon>{" "}
                Address Information
              </MDTypography>
              <MDBox p={2} shadow="md" mb={4}>
                <MDBox display="flex" flexDirection="row" columnGap={2}>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Address 1
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.address1}
                      name="address1"
                      onChange={changeHandler}
                      error={errors.address1}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Address 2
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.address2}
                      name="address2"
                      onChange={changeHandler}
                      error={errors.address2}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
                <MDBox display="flex" flexDirection="row" columnGap={2}>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      City
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.city}
                      name="city"
                      onChange={changeHandler}
                      error={errors.city}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      State
                    </MDTypography>
                    <Select
                      id="stateSelector"
                      value={inputs.state}
                      name="state"
                      onChange={changeHandler}
                      fullWidth
                      sx={{
                        "& .MuiSelect-icon": {
                          display: "flex",
                        },
                        height: "2.8rem",
                      }}
                      error={errors.state}
                    >
                      {UsStates.map((state, index) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                  <MDBox
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                  >
                    <MDTypography variant="h7" color="text" fontWeight="bold">
                      Zip
                    </MDTypography>
                    <MDInput
                      type="text"
                      value={inputs.zip}
                      name="zip"
                      onChange={changeHandler}
                      error={errors.zip}
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDTypography
                variant="h6"
                color="text"
                display="flex"
                columnGap={1}
                alignItems="center"
              >
                <Icon sx={{ color: "gray" }} fontSize="small">
                  contact_phone
                </Icon>{" "}
                Referral Information
              </MDTypography>
              <MDBox p={2} shadow="md" mb={4}>
                <MDBox
                  mb={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  width="100%"
                >
                  <MDTypography variant="h7" color="text" fontWeight="bold">
                    Referred By
                  </MDTypography>
                  <MDInput
                    type="text"
                    value={inputs.referredBy}
                    name="referredBy"
                    onChange={changeHandler}
                    error={errors.referredBy}
                    fullWidth
                  />
                </MDBox>
              </MDBox>

              {/* Note */}
              <MDTypography
                variant="h6"
                color="text"
                display="flex"
                columnGap={1}
                alignItems="center"
              >
                <Icon sx={{ color: "gray" }} fontSize="small">
                  edit_note
                </Icon>{" "}
                Note
              </MDTypography>
              <MDBox p={2} shadow="md" mb={4}>
                <MDBox
                  mb={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  width="100%"
                >
                  <MDInput
                    type="text"
                    value={inputs.note}
                    name="note"
                    onChange={changeHandler}
                    error={errors.note}
                    fullWidth
                    multiline
                    minRows="2"
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          )}

          {/* Step 1, student settings */}
          {activeStep == 1 && (
            <MDBox p={3}>
              {/*  Enter Art Contests */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Enter Art Contests
                </MDTypography>
                <Select
                  id="enter_art_contests_selector"
                  value={inputs.enter_art_contests}
                  name="enter_art_contests"
                  onChange={changeHandler}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "2.8rem",
                  }}
                  error={errors.enter_art_contests}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </MDBox>

              {/*  Exhibit Artwork */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Exhibit Artwork
                </MDTypography>
                <Select
                  id="exhibit_artwork_selector"
                  value={inputs.exhibit_artwork}
                  name="exhibit_artwork"
                  onChange={changeHandler}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "2.8rem",
                  }}
                  error={errors.exhibit_artwork}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </MDBox>

              {/* Receive Emails */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Receive Emails
                </MDTypography>
                <Select
                  id="receive_emails_selector"
                  value={inputs.receive_emails}
                  name="receive_emails"
                  onChange={changeHandler}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "2.8rem",
                  }}
                  error={errors.receive_emails}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </MDBox>

              {/* Receive Text */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Receive Text
                </MDTypography>
                <Select
                  id="receive_text"
                  value={inputs.receive_text}
                  name="receive_text"
                  onChange={changeHandler}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "2.8rem",
                  }}
                  error={errors.receive_text}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </MDBox>

              {/* sell artwork permission */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Sell Artwork
                </MDTypography>
                <Select
                  id="sell_artwork_permission"
                  value={inputs.sell_artwork_permission}
                  name="sell_artwork_permission"
                  onChange={changeHandler}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "2.8rem",
                  }}
                  error={errors.sell_artwork_permission}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </MDBox>

              {/* Payment Method */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Payment Method
                </MDTypography>
                <Select
                  id="payment_method"
                  value={inputs.payment_method}
                  name="payment_method"
                  onChange={changeHandler}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "2.8rem",
                  }}
                  error={errors.payment_method}
                >
                  <MenuItem value={1}>Cash</MenuItem>
                  <MenuItem value={2}>Zelle</MenuItem>
                  <MenuItem value={3}>Venmo</MenuItem>
                  <MenuItem value={4}>Paypal</MenuItem>
                  <MenuItem value={5}>Check</MenuItem>
                </Select>
              </MDBox>
            </MDBox>
          )}

          {/* Step 2, student preferred time schedule */}
          {activeStep == 2 && (
            <MDBox p={3}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Preferred Class
                  Schedule
                </MDTypography>
                <Select
                  id="preferredSchedulePicker"
                  name="day"
                  onChange={handleScheduleChange}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "2.8rem",
                  }}
                  error={errors.day}
                >
                  {avaliableTimeSlots &&
                    avaliableTimeSlots.map((slot, i) => (
                      <MenuItem value={slot.id} key={i}>
                        {slot.day}
                        <i>
                          ({defaultconvertTimeTo12HourFormat(slot.start_time)} -{" "}
                          {defaultconvertTimeTo12HourFormat(slot.end_time)})
                        </i>
                      </MenuItem>
                    ))}
                </Select>
              </MDBox>
            </MDBox>
          )}

          {/* Stepper Buttons */}
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            color="primary"
            sx={{
              flexGrow: 1,
              background: "rgba(0,0,0,0)",
              "& .MuiLinearProgress-root": {
                "& .MuiLinearProgress-bar": {
                  backgroundColor: colors.info.main, // Primary color for the progress bar
                },
              },
              "& .MuiMobileStepper-dot": {
                backgroundColor: "lightblue", // Color for inactive dots
              },
              "& .MuiMobileStepper-dotActive": {
                backgroundColor: colors.info.main, // Color for the active dot
              },
            }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === 2}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />

          {activeStep == 2 && (
            <DialogActions>
              {/* Form Here */}
              <MDButton
                variant="contained"
                color="light"
                onClick={handleCancel}
                disabled={processing}
              >
                No
              </MDButton>
              <MDButton
                variant="contained"
                color="info"
                onClick={handleSubmit}
                disabled={processing}
              >
                Submit{" "}
                {processing && (
                  <CircularProgress
                    size={14}
                    sx={{ marginLeft: 1 }}
                    color="warning"
                  />
                )}
              </MDButton>
            </DialogActions>
          )}
        </MDBox>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddProspectStudent;
