import { useEffect, useState } from "react";

import {
  Card,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { UsStates } from "assets/constants/UsStates";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import studentAPIService from "services/studentAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AddStudentDialog(props) {
  const { open, onClose, existingDetails } = props;

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    firstName: "",
    lastName: "",
    parent1FirstName: "",
    parent1LastName: "",
    email1: "",
    phone1: "",
    parent2FirstName: "",
    parent2LastName: "",
    email2: "",
    phone2: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    referredBy: "",
    note: "",
    status: "Active",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
    setProcessing(false);
  };

  useEffect(() => {
    if (existingDetails?.id) {
      setInputs({
        firstName: existingDetails?.first_name,
        lastName: existingDetails?.last_name,
        parent1FirstName: existingDetails?.parent_1_first_name,
        parent1LastName: existingDetails?.parent_1_last_name,
        email1: existingDetails?.contact_1_email,
        phone1: existingDetails?.phone_1,

        parent2FirstName: existingDetails?.parent_2_first_name,
        parent2LastName: existingDetails?.parent_2_last_name,
        email2: existingDetails?.contact_2_email,
        phone2: existingDetails?.phone_2,

        address1: existingDetails?.address_1,
        address2: existingDetails?.address_2,
        city: existingDetails?.city,
        state: existingDetails?.state,
        zip: existingDetails?.zip_code,
        referredBy: existingDetails?.referred_by,
        note: existingDetails?.note,
        status: existingDetails?.status,
      });
    }
  }, [existingDetails, open]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.firstName) {
      setErrors({ ...errors, firstName: true });
      return;
    } else {
      setErrors({ ...errors, firstName: false });
    }

    if (!inputs.lastName) {
      setErrors({ ...errors, lastName: true });
      return;
    } else {
      setErrors({ ...errors, lastName: false });
    }

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!inputs.email1 || inputs.email1.trim().length === 0) {
      setErrors({ ...errors, email1: false });
    } else {
      if (!inputs.email1.trim().match(mailFormat)) {
        setErrors({ ...errors, email1: true });
        return;
      }
    }

    if (!inputs.email2 || inputs.email2.trim().length === 0) {
      setErrors({ ...errors, email2: false });
    } else {
      if (!inputs.email2.trim().match(mailFormat)) {
        setErrors({ ...errors, email2: true });
        return;
      }
    }

    // Now that everything is verified
    setProcessing(true);
    try {
      let payload = {
        first_name: inputs.firstName,
        last_name: inputs.lastName,
        parent_1_first_name: inputs.parent1FirstName,
        parent_1_last_name: inputs.parent1LastName,
        contact_1_email: inputs.email1,
        phone_1: inputs.phone1,
        parent_2_first_name: inputs.parent2FirstName,
        parent_2_last_name: inputs.parent2LastName,
        contact_2_email: inputs.email2,
        phone_2: inputs.phone2,
        address_1: inputs.address1,
        address_2: inputs.address2,
        city: inputs.city,
        state: inputs.state,
        zip_code: inputs.zip,
        referred_by: inputs.referredBy,
        note: inputs.note,
        status: inputs.status,
      };

      if (existingDetails?.id) {
        const response = await studentAPIService.updateStudent(
          payload,
          existingDetails.id
        );
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      } else {
        const response = await studentAPIService.addStudent(payload);
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      }
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to add the Student.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          {existingDetails ? "Edit" : "Add New"} Student
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>
      <DialogContent>
        {/* Name */}
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              person
            </Icon>
            General Information
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> First Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.firstName}
                  name="firstName"
                  onChange={changeHandler}
                  error={errors.firstName}
                  fullWidth
                />
              </MDBox>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Last Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.lastName}
                  name="lastName"
                  onChange={changeHandler}
                  error={errors.lastName}
                  fullWidth
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              family_restroom
            </Icon>
            Parent 1 Information
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Parent First Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.parent1FirstName}
                  name="parent1FirstName"
                  onChange={changeHandler}
                  error={errors.parent1FirstName}
                  fullWidth
                />
              </MDBox>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Parent Last Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.parent1LastName}
                  name="parent1LastName"
                  onChange={changeHandler}
                  error={errors.parent1LastName}
                  fullWidth
                />
              </MDBox>
            </MDBox>

            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Email
                </MDTypography>
                <MDInput
                  type="email"
                  value={inputs.email1}
                  name="email1"
                  onChange={changeHandler}
                  error={errors.email1}
                  fullWidth
                />
              </MDBox>

              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Phone
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.phone1}
                  name="phone1"
                  onChange={changeHandler}
                  error={errors.phone1}
                  fullWidth
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              family_restroom
            </Icon>
            Parent 2 Information
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Parent First Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.parent2FirstName}
                  name="parent2FirstName"
                  onChange={changeHandler}
                  error={errors.parent2FirstName}
                  fullWidth
                />
              </MDBox>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Parent Last Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.parent2LastName}
                  name="parent2LastName"
                  onChange={changeHandler}
                  error={errors.parent2LastName}
                  fullWidth
                />
              </MDBox>
            </MDBox>

            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Email
                </MDTypography>
                <MDInput
                  type="email"
                  value={inputs.email2}
                  name="email2"
                  onChange={changeHandler}
                  error={errors.email2}
                  fullWidth
                />
              </MDBox>

              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Phone
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.phone2}
                  name="phone2"
                  onChange={changeHandler}
                  error={errors.phone2}
                  fullWidth
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              home
            </Icon>{" "}
            Address Information
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Address 1
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.address1}
                  name="address1"
                  onChange={changeHandler}
                  error={errors.address1}
                  fullWidth
                />
              </MDBox>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Address 2
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.address2}
                  name="address2"
                  onChange={changeHandler}
                  error={errors.address2}
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  City
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.city}
                  name="city"
                  onChange={changeHandler}
                  error={errors.city}
                  fullWidth
                />
              </MDBox>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  State
                </MDTypography>
                <Select
                  id="stateSelector"
                  value={inputs.state}
                  name="state"
                  onChange={changeHandler}
                  fullWidth
                  sx={{
                    "& .MuiSelect-icon": {
                      display: "flex",
                    },
                    height: "2.8rem",
                  }}
                  error={errors.state}
                >
                  {UsStates.map((state, index) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Zip
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.zip}
                  name="zip"
                  onChange={changeHandler}
                  error={errors.zip}
                  fullWidth
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              contact_phone
            </Icon>{" "}
            Referral Information
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                Referred By
              </MDTypography>
              <MDInput
                type="text"
                value={inputs.referredBy}
                name="referredBy"
                onChange={changeHandler}
                error={errors.referredBy}
                fullWidth
              />
            </MDBox>
          </MDBox>

          {/* Note */}
          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              edit_note
            </Icon>{" "}
            Note
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDInput
                type="text"
                value={inputs.note}
                name="note"
                onChange={changeHandler}
                error={errors.note}
                fullWidth
                multiline
                minRows="2"
              />
            </MDBox>
          </MDBox>

          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Status
            </MDTypography>
            <Select
              id="statusSelector"
              value={inputs.status}
              name="status"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-select.MuiSelect-select": {
                  color:
                    inputs.status === "Active"
                      ? colors.success.main
                      : inputs.status === "Prospective"
                      ? colors.warning.main
                      : inputs.status === "Waitlist"
                      ? colors.info.main
                      : colors.error.main,
                  height: "2.8rem",
                },
                "& .MuiSelect-icon": {
                  display: "flex",
                },
              }}
            >
              <MenuItem value={"Active"}>Active</MenuItem>
              <MenuItem value={"Inactive"}>Inactive</MenuItem>
              <MenuItem value={"Prospective"}>Prospective</MenuItem>
              <MenuItem value={"Waitlist"}>Waitlist</MenuItem>
            </Select>
          </MDBox>
        </MDBox>

        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddStudentDialog;
