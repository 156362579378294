// Material Dashboard 2 React example components
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import MDTypography from "components/MDTypography";

import DeleteConfirmationDialog from "components/Dialog/DeleteDialog";
import Footer from "components/Footer";

import APIError from "components/ApiError";
import dateFormatter from "components/Formatter/DateFormatter";
import defaultconvertTimeTo12HourFormat from "components/Formatter/HourMinuteFormatter";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import { Link } from "react-router-dom";
import attendanceAPIService from "services/attendanceAPI-service";
import dayExtractor from "components/Formatter/DayExtractor";

export default function VerifyAttendace() {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [verifyProcessing, setVerifyProcessing] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selected, setSelected] = useState([]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const [verifyAllDialogOpen, setVerifyAllDialogOpen] = useState(false);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [rejectItem, setRejectItem] = useState("");

  const columns = ["Student", "Date", "Start", "End", "Action"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSelected([]);
    setIsAllSelected(false);

    fetchAttendanceOnHoldData();
  }, [page, rowsPerPage]);

  const fetchAttendanceOnHoldData = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const holdList = await attendanceAPIService.getAllAttendanceOnHold(
        rowsPerPage,
        page + 1
      );
      setTotal(holdList.data.count);
      setRows(holdList.data.results);
      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleVerifyALLConfirmation = () => {
    setDialogMessage(
      "Are you sure you want to mark these " +
        selected.length +
        " attendance record as verified?"
    );
    setVerifyAllDialogOpen(true);
  };

  const handleDeleteALLConfirmation = () => {
    setDialogMessage(
      "Are you sure you want to reject these " +
        selected.length +
        " attendance record?"
    );
    setDeleteAllDialogOpen(true);
  };

  const handleRejectConfirmation = (id) => {
    setDialogMessage("Are you sure you want to reject this attendance record?");
    setRejectItem(id);
    setRejectDialogOpen(true);
  };

  const handleVerifyAllClose = async (response) => {
    if (response === "No") {
      setVerifyAllDialogOpen(false);
    } else {
      setVerifyProcessing(true);
      let verifyCount = 0;
      let errorCount = 0;
      // loop through each ids in selected
      const verifyResponse = selected.map(async (id) => {
        let payload = {
          validated: true,
        };

        try {
          const individualResponse =
            await attendanceAPIService.markAttendaceAsVerified(id, payload);
          verifyCount = verifyCount + 1;
        } catch (error) {
          errorCount = errorCount + 1;
        }
      });

      // Wait for all promises to be over

      const results = await Promise.all(verifyResponse);

      let newPage = page;
      if (rows.length - (verifyCount + errorCount) <= 0) {
        newPage = newPage - 1;
        if (newPage < 0) {
          newPage = 0;
        }
      }

      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage(
        verifyCount + " attendance records have been verified."
      );
      openSnackBar();

      setSelected([]);
      setIsAllSelected(false);
      setVerifyProcessing(false);
      setPage(newPage);

      fetchAttendanceOnHoldData();
      setVerifyAllDialogOpen(false);
    }
  };

  const handleDeleteALLClose = async (response) => {
    if (response === "No") {
      setDeleteAllDialogOpen(false);
    } else {
      setVerifyProcessing(true);
      let deleteCount = 0;
      let errorCount = 0;
      // loop through each ids in selected
      const deletePromises = selected.map(async (id) => {
        try {
          const deleteResponse =
            await attendanceAPIService.rejectAttendanceOnHold(id);
          deleteCount = deleteCount + 1;
        } catch (error) {
          errorCount = errorCount + 1;
        }
      });

      // Wait for all promises to be over

      const results = await Promise.all(deletePromises);

      let newPage = page;
      if (rows.length - (deleteCount + errorCount) <= 0) {
        newPage = newPage - 1;
        if (newPage < 0) {
          newPage = 0;
        }
      }

      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage(
        deleteCount + " attendance records have been rejected."
      );
      openSnackBar();

      setSelected([]);
      setIsAllSelected(false);
      setVerifyProcessing(false);
      setPage(newPage);

      fetchAttendanceOnHoldData();
      setDeleteAllDialogOpen(false);
    }
  };

  const handleRejectClose = async (response) => {
    if (response === "No") {
      setRejectItem(null);
    } else {
      setVerifyProcessing(true);

      try {
        const response = await attendanceAPIService.rejectAttendanceOnHold(
          rejectItem
        );

        setSnackBarColor("success");
        setSnackBarIcon("check");
        setSnackBarTitle("Success");
        setSnackBarMessage("Attendance record has been rejected.");
        openSnackBar();
        setVerifyProcessing(false);

        // Handle Pagination and reload
        let newPage = page;
        if (rows.length === 1) {
          newPage = newPage - 1;
          if (newPage < 0) {
            newPage = 0;
          }
        }
        setPage(newPage);
        fetchAttendanceOnHoldData();
      } catch (error) {
        setSnackBarColor("error");
        setSnackBarIcon("warning");
        setSnackBarTitle("Error");
        setSnackBarMessage("Failed to reject the attendance record.");
        openSnackBar();

        setVerifyProcessing(false);
      }
    }

    setRejectDialogOpen(false);
  };

  const handleVerify = async (row) => {
    setVerifyProcessing(true);
    try {
      let payload = {
        validated: true,
      };
      const response = await attendanceAPIService.markAttendaceAsVerified(
        row.id,
        payload
      );

      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage(response.data.message);
      openSnackBar();
      setVerifyProcessing(false);

      // Handle Pagination and reload
      let newPage = page;
      if (rows.length === 1) {
        newPage = newPage - 1;
        if (newPage < 0) {
          newPage = 0;
        }
      }
      setPage(newPage);
      fetchAttendanceOnHoldData();
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to verify the attendance record.");
      openSnackBar();

      setVerifyProcessing(false);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setIsAllSelected(true);
      return;
    }
    setIsAllSelected(false);
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    if (!processing) {
      if (selected.length === rows.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
    }
  }, [selected]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const renderVerifyALLDialog = (
    <DeleteConfirmationDialog
      open={verifyAllDialogOpen}
      onClose={handleVerifyAllClose}
      message={dialogMessage}
    />
  );

  const renderDeleteALLDialog = (
    <DeleteConfirmationDialog
      open={deleteAllDialogOpen}
      onClose={handleDeleteALLClose}
      message={dialogMessage}
    />
  );

  const renderDeleteDialog = (
    <DeleteConfirmationDialog
      open={rejectDialogOpen}
      onClose={handleRejectClose}
      message={dialogMessage}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={10} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* Heading */}
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Verify Student's Attendance
                </MDTypography>
              </MDBox>
              {/* Progress Bar */}
              {(processing || verifyProcessing) && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {/* // If More than one box is selected */}
              {selected.length > 0 && (
                <MDBox
                  mb={{ xs: 3, sm: 0 }}
                  mt={2}
                  px={3}
                  shadow="none"
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <MDTypography
                    variant="h7"
                    color="secondary"
                    fontWeight="bold"
                  >
                    {selected.length} Entries Selected
                  </MDTypography>
                  <MDBox display="flex" flexWrap="wrap" gap={2}>
                    <MDButton
                      variant="contained"
                      color="success"
                      size="small"
                      disabled={verifyProcessing}
                      onClick={() => {
                        handleVerifyALLConfirmation();
                      }}
                    >
                      Verify All
                    </MDButton>

                    <MDButton
                      variant="contained"
                      color="error"
                      size="small"
                      disabled={verifyProcessing}
                      onClick={() => {
                        handleDeleteALLConfirmation();
                      }}
                    >
                      Reject All
                    </MDButton>
                  </MDBox>
                </MDBox>
              )}
              {/* No Data */}
              {!processing && !apiError && total === 0 && (
                <MDBox pt={3} px={4} pb={5}>
                  <MDTypography variant="h7" color="error">
                    Currently, no attendance are on hold
                  </MDTypography>
                </MDBox>
              )}

              {/* Table */}
              {!processing && total > 0 && (
                <MDBox pt={3} px={1} pb={5}>
                  <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table>
                      {/* Header Columns */}
                      <TableHead>
                        <TableRow>
                          {/* CheckBox */}
                          <TableCell>
                            <Checkbox
                              checked={isAllSelected}
                              color="primary"
                              size="medium"
                              onClick={handleSelectAllClick}
                            />
                          </TableCell>

                          {columns.map((column, i) => (
                            <TableCell key={i}>{column}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* Body Contents */}
                      <TableBody>
                        {rows.map((row, i) => (
                          <TableRow key={i}>
                            {/* CheckBox */}
                            <TableCell>
                              <Checkbox
                                checked={isSelected(row.id)}
                                color="primary"
                                size="medium"
                                onClick={(event) => handleClick(event, row.id)}
                              />
                            </TableCell>

                            <TableCell>
                              <Link to={`/students/${row.student.id}`}>
                                <MDTypography
                                  variant="h7"
                                  fontWeight="bold"
                                  color="info"
                                >
                                  {row.student.first_name}&nbsp;
                                  {row.student.last_name}
                                </MDTypography>
                              </Link>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {dayExtractor(row.date)},{" "}
                                {dateFormatter(row.date)}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {defaultconvertTimeTo12HourFormat(
                                  row.start_time
                                )}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {defaultconvertTimeTo12HourFormat(row.end_time)}
                              </MDTypography>
                            </TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                columnGap: "20px",
                                rowGap: "20px",
                              }}
                            >
                              <MDButton
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={() => {
                                  handleVerify(row);
                                }}
                                disabled={verifyProcessing}
                              >
                                Verify
                              </MDButton>
                              <MDButton
                                variant="contained"
                                color="error"
                                size="small"
                                onClick={() => {
                                  handleRejectConfirmation(row.id);
                                }}
                                disabled={verifyProcessing}
                              >
                                Reject
                              </MDButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* Pagination */}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderDeleteDialog}
      {renderSnackBar}
      {renderDeleteALLDialog}
      {renderVerifyALLDialog}
      {/* API Error */}
      {!processing && apiError && <APIError />}
      <Footer />
    </DashboardLayout>
  );
}
