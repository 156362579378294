// @mui material components
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import { LinearProgress } from "@mui/material";
import APIError from "components/ApiError";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import paymentAPIService from "services/paymentAPI-service";

import CashIcon from "assets/icons/cash.svg";
import CheckIcon from "assets/icons/check.png";
import PaypalIcon from "assets/icons/paypal.png";
import VenmoIcon from "assets/icons/venmo.png";
import ZelleIcon from "assets/icons/zelle.png";

function PaymentTypes() {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  const methodMaps = new Map()
    .set("Cash", {
      icon: CashIcon,
      color: "white",
    })
    .set("Zelle", {
      icon: ZelleIcon,
      color: "white",
    })
    .set("Venmo", {
      icon: VenmoIcon,
      color: "white",
    })
    .set("Paypal", {
      icon: PaypalIcon,
      color: "white",
    })
    .set("Check", {
      icon: CheckIcon,
      color: "white",
    });

  useEffect(() => {
    fetchAllPaymentTypes();
  }, []);

  const fetchAllPaymentTypes = async () => {
    setProcessing(true);
    setApiError(false);

    try {
      const paymentResponse = await paymentAPIService.getAllPaymentMethods();
      setTotal(paymentResponse.data.count);
      setRows(paymentResponse.data.results);
      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            {/* Heading */}
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Payment Methods
              </MDTypography>
            </MDBox>

            {/* Progress Bar */}
            {processing && (
              <MDBox pt={2} px={2}>
                <LinearProgress
                  color="info"
                  variant="indeterminate"
                  sx={{ overflow: "hidden" }}
                />
              </MDBox>
            )}

            {/* No Data */}
            {!processing && !apiError && total === 0 && (
              <MDBox pt={3} px={4} pb={5}>
                <MDTypography variant="h7" color="error">
                  No cost record found
                </MDTypography>
              </MDBox>
            )}

            {/* Table */}
            {!processing && total > 0 && (
              <MDBox
                pt={3}
                px={1}
                pb={5}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                gap={4}
              >
                {rows.map((row, i) => (
                  <DefaultInfoCard
                    key={row.id}
                    color={methodMaps.get(row.payment_method).color}
                    icon={methodMaps.get(row.payment_method).icon}
                    title={row.payment_method}
                  />
                ))}
              </MDBox>
            )}

            {/* Api Error */}
            {!processing && apiError && <APIError />}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default PaymentTypes;
