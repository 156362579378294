import axios from "axios";

class AuthService {
  login = async (payload) => {
    return await axios({
      method: "post",
      url: process.env.REACT_APP_AUTH_SERVER_URL,
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      auth: {
        username: payload.username,
        password: payload.password,
      },
    });
  };

  parentlogin = async (payload) => {
    return await axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/payment/send-sms-token/",
      data: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json", // Set Content-Type to JSON
      },
    });
  };

  verifyParentOTP = async (payload) => {
    return await axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/payment/verify-sms-token/",
      data: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json", // Set Content-Type to JSON
      },
    });
  };
}

export default new AuthService();
