import { useEffect, useState } from "react";
// react-router components
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Custom components
import BrandLogo from "assets/logos/EDMIN-BNGB.png";

import Logout from "appStack/logout";
import ProtectedRoute from "layouts/ProtectedRoute";
import { useSelector } from "react-redux";
import { setupAxiosInterceptors } from "services/interceptor";
import theme from "./assets/theme";
import ForgotPassword from "./auth/forgot-password";
import Login from "./auth/login";
import Register from "./auth/register";
import ResetPassword from "./auth/reset-password";
import Sidenav from "./components/Sidenav";
import { setMiniSidenav, useMaterialUIController } from "./context";
import routes from "./routes";
import ParentLogin from "auth/parent-login";

function App() {
  // Dispatch and user auth selector
  const navigate = useNavigate();
  const userSelector = useSelector((state) => state.auth);
  const [controller, dispatch] = useMaterialUIController();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Setup Axios Interceptor to add token
  setupAxiosInterceptors(userSelector, navigate);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Make all these routes as Protected Route where it forces user to be logged in
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (
        route.route &&
        route.type !== "auth" &&
        Array.isArray(route.role) &&
        route.role.includes(userSelector.userType)
      ) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={userSelector.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === "dashboard" &&
          pathname != "/registerprospectstudent" &&
          Array.of("staff", "admin", "superadmin", "parent").includes(
            userSelector.userType
          ) && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={BrandLogo} // For logo
                brandName="Edmin"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
        <Routes>
          {!userSelector.isAuthenticated && (
            <Route path="/" exact element={<Login />} />
          )}
          <Route path="/auth/login" exact element={<Login />} />
          <Route path="/auth/register" exact element={<Register />} />
          <Route path="auth/parent" exact element={<ParentLogin />} />
          <Route
            path="/auth/forgot-password"
            exact
            element={<ForgotPassword />}
          />
          <Route
            path="/auth/reset-password"
            exact
            element={<ResetPassword />}
          />
          {/* Get all authenticated Routes */}
          {getRoutes(routes)}
          {/* Logout Route */}
          <Route path="/logout" exact element={<Logout />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
