// Material Dashboard 2 React example components
import { Card } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MDBox from "components/MDBox";
import Footer from "../../components/Footer";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";

import scheduleBG from "assets/images/corporate2.jpg";
import APIError from "components/ApiError";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import SendCustomMessage from "./customSMS";
import MDSnackbar from "components/MDSnackbar";
import UnpaidReminders from "./unpaidReminders";

function SMS() {
  const [processing, setProcessing] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [addCustomMessageDialogOpen, setAddCustomMessageDialogOpen] =
    useState(false);
  const [addUnpaidMessageDialogOpen, setAddUnpaidMessageDialogOpen] =
    useState(false);

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const handleAddUnpaidMessage = () => {
    setAddUnpaidMessageDialogOpen(true);
  };

  const handleAddNewCustomMessage = () => {
    setAddCustomMessageDialogOpen(true);
  };

  const handleCustomMessageDialogClose = (response) => {
    if (response === "Close") {
      setAddCustomMessageDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Message has been successfully sent.");
      openSnackBar();
    }
    setAddCustomMessageDialogOpen(false);
  };

  const handleUnpaidMessageDialogClose = (response) => {
    if (response === "Close") {
      setAddUnpaidMessageDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Message has been successfully sent.");
      openSnackBar();
    }
    setAddUnpaidMessageDialogOpen(false);
  };

  const renderAddCustomMessageDialog = (
    <SendCustomMessage
      open={addCustomMessageDialogOpen}
      onClose={handleCustomMessageDialogClose}
    />
  );

  const renderUnpaidMessageDialog = (
    <UnpaidReminders
      open={addUnpaidMessageDialogOpen}
      onClose={handleUnpaidMessageDialogClose}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={10} pb={10} overflow="auto">
        <Card>
          {/* Heading */}
          <MDBox
            mx={2}
            mt={-6}
            py={4}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="xxl"
            coloredShadow="info"
            height="100px"
            sx={{
              backgroundImage: ({
                functions: { rgba, linearGradient },
                palette: { gradients },
              }) =>
                `${linearGradient(
                  rgba(gradients.info.main, 0.7),
                  rgba(gradients.info.state, 0.1)
                )}, url(${scheduleBG})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          >
            <MDTypography variant="h4" color="light">
              SMS
            </MDTypography>
          </MDBox>

          {/*  Buttons */}
          <MDBox
            mb={1}
            mt={2}
            pr={2}
            display="flex"
            justifyContent="end"
            gap={2}
            flexWrap="wrap"
          >
            <MDButton
              variant="contained"
              color="dark"
              type="button"
              onClick={handleAddUnpaidMessage}
            >
              + Send Unpaid Reminder
            </MDButton>

            <MDButton
              variant="contained"
              color="dark"
              type="button"
              onClick={handleAddNewCustomMessage}
            >
              + Send New Message
            </MDButton>
          </MDBox>

          {/* Processing Bar */}
          {processing && (
            <MDBox pt={2} px={2}>
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            </MDBox>
          )}
        </Card>
      </MDBox>

      {!processing && apiError && <APIError />}
      {addCustomMessageDialogOpen && renderAddCustomMessageDialog}
      {addUnpaidMessageDialogOpen && renderUnpaidMessageDialog}
      {renderSnackBar}
      <Footer />
    </DashboardLayout>
  );
}

export default SMS;
